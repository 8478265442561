import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
//import { getproductss, updateproductsStatus } from "./api"; // API functions to interact with the backend
import { URL } from "../../config";
import "./MarketAdmin.css";
import Spinner from "../../assets/WeblandSpinner";
import SuccessModal from "../Desktop/SuccessModal";
import DeleteConfirmationModal from "../Desktop/DeleteModal";
import { AuthContext } from "../AuthContext";
import { FaThList } from "react-icons/fa";

const MarketAdmin = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editableProductData, setEditableProductData] = useState({});
  const [isAddingNewProduct, setIsAddingNewProduct] = useState(false);
  //estado que controla a abretura do MODAL SUCCESS
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  //estado que controla a abertura e eliminação do MODAL DELETE
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [activeItemPhoto, setactiveItemPhoto] = useState(false);
  const [showProductsGroup, setShowProductsGroup] = useState(true);
  const [rates, setRates] =useState(new Set());
  const [imagePreview, setImagePreview] = useState(null);

  const { token } = useContext(AuthContext);
  const [imageSources, setImageSources] = useState({}); // To store image sources

  // Function to fetch an image for a single product
  const fetchImageForProduct = (product) => {
    const imageUrl = `${URL}/webland-api/product/image/${product._id}`;
    const fetchOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`, // Ensure you use the correct token variable here
      },
    };

    fetch(imageUrl, fetchOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        // Update the imageSources state with the Blob URL
        setImageSources((prev) => ({ ...prev, [product._id]: blobUrl }));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  const openDeleteConfirmationModal = (editableProductData) => {
    setProductToDelete(editableProductData);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteModalOpen(false);
    setProductToDelete(null);
  };

  const handleConfirmDelete = () => {
    const menssageDelete = "Produto Eliminado com sucesso";
    fetch(
      `${URL}/webland-api/product/deleteproducts/${editableProductData._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    //console.log("Excluir produto:", productToDelete);

    setSuccessMessage(menssageDelete);
    setIsSuccessModalOpen(true);
    setEditableProductData({
      type: "",
      description: "",
      packageType: "",
      price: "",
      imageUrl: "",
    });
    closeDeleteConfirmationModal(); // Fechar o modal após a exclusão
  };

  const handleDelete = () => {
    openDeleteConfirmationModal(editableProductData);
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    fetchProductsAndUpdate();
  };

  const fetchProductsAndUpdate = () => {
    fetchProducts();
  };

  const handleNewProduct = () => {
    history.push(`/market/AddProduct`);
    setIsAddingNewProduct(true);
    setSelectedProduct(null);
    setEditableProductData({
      type: "",
      description: "",
      packageType: "",
      price: "",
      imageData: "",
    });
    setIsEditing(true);
  };

  const handleProductClick = (product) => {
    history.push(`/market/product?id=${product._id}`);
    console.log("product.id", product._id);
    //setSelectedProduct(product);
    setShowProductsGroup(false);
    setEditableProductData({ ...product });
    setIsEditing(false); // Disable editing by default when a product is clicked
    setIsAddingNewProduct(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditableProductData({ ...selectedProduct });
    setIsEditing(false);
  };

  const handleSave = async () => {
    let url;
    let options;

    if (isAddingNewProduct) {
      url = `${URL}/webland-api/product/addproducts`;
      const formData = new FormData();
      formData.append("type", editableProductData.type);
      formData.append("description", editableProductData.description);
      formData.append("packageType", editableProductData.packageType);
      formData.append("price", editableProductData.price);
      formData.append("weight", editableProductData.weight);
      formData.append("certification", editableProductData.certification);
      formData.append("nutritionalValue", editableProductData.nutritionalValue);
      formData.append("expirationDate", editableProductData.expirationDate);
      formData.append("origin", editableProductData.origin);
      formData.append("prv_pvp", editableProductData.prv_pvp);
      formData.append("stock", editableProductData.stock);
      formData.append("EAN_13", editableProductData.EAN_13);
      formData.append("ITF_14", editableProductData.ITF_14);
      formData.append("carbonFootprint", editableProductData.carbonFootprint);

      if (editableProductData.imageData instanceof File) {
        // Check if imageData is a File
        formData.append("imageData", editableProductData.imageData);
      }

      options = {
        method: "POST",
        headers: {
          // Don't set Content-Type for FormData; let the browser set it
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };
    } else {
      // Existing product update logic here (assuming JSON)
      url = `${URL}/webland-api/product/editproducts/${editableProductData._id}`;
      console.log("body", imagePreview);
      options = {
        method: "PUT", // or PATCH, depending on your backend
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          type: editableProductData.type,
          description: editableProductData.description,
          packageType: editableProductData.packageType,
          price: editableProductData.price,
          weight: editableProductData.weight,
          imageData: imagePreview,
          certification: editableProductData.certification,
          nutritionalValue: editableProductData.nutritionalValue,
          expirationDate: editableProductData.expirationDate,
          origin: editableProductData.origin,
          prv_pvp: editableProductData.prv_pvp,
          stock: editableProductData.stock,
          EAN_13: editableProductData.EAN_13,
          ITF_14: editableProductData.ITF_14,
          carbonFootprint: editableProductData.carbonFootprint,

          // Do not send imageData for updates unless it's a new File
        }),
      };
    }

    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(true);
        setSuccessMessage(
          isAddingNewProduct
            ? "Produto criado com sucesso!"
            : "Produto atualizado com sucesso!"
        );
        setIsSuccessModalOpen(true);
        fetchProductsAndUpdate();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Save operation failed:", error);
      });

    setIsEditing(false);
  };
  const history = useHistory();

  const fetchProducts = () => {
    fetch(`${URL}/webland-api/product/products`, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
        //setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        //setIsLoading(false);
      });
    // console.log("teste", setProducts);
  };

  const fetchRates = async () => {
    try {
      const ratesResponse = await fetch(
        `${URL}/webland-api/rates/conversion-rate`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!ratesResponse.ok) {
        throw new Error(`HTTP error! Rates status: ${ratesResponse.status}`);
      }

      const dataRates = await ratesResponse.json();

      setRates(new Set(dataRates.map((rate) => rate.product._id)));
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error state appropriately
    }
  };

  useEffect(() => {}, [rates]);

  useEffect(() => {
    // Fetch the image for each product when the component mounts
    products.forEach((product) => {
      if (product.imageData) {
        fetchImageForProduct(product);
      }
    });
  }, [products]);

  useEffect(() => {
    // setIsLoading(true);
    const fetchData = async () => {
      await Promise.all([fetchProducts(), fetchRates()]);

      // setIsLoading(false);
    };

    // setIsLoading(true);
    fetchData();
  }, [selectedProduct]);

  const handleUpdateStatus = async (productsId, newStatus) => {
    // await updateproductsStatus(productsId, newStatus); // Replace with your API call to update products status
    //fetchproductss();
  };

  const handleInputChange = useCallback((name, value) => {
    setEditableProductData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  return isLoading ? (
    <div className="col-12 spinner-container-market">
      <Spinner />
    </div>
  ) : (
    <div className="admin-market-wrapper">
      <div
        className="col-12 card-table-header"
        style={{ background: "var(--secondary-color)" }}
      >
        PRODUTOS
        {!showProductsGroup && (
          <button
            className="market-group-btn"
            onClick={() => setShowProductsGroup(true)}
          >
            <FaThList size={15} />
          </button>
        )}
      </div>

      {showProductsGroup && (
        <div className="col-12 admin-card-table-body" style={{ maxHeight: "80vh"}}>
          <div className="table-scroll-container" >
            <div className="admin-market-group">
              {products?.map((product) => (
                <div
                  key={product._id}
                  className={
                    "admin-product-card" +
                    (selectedProduct && selectedProduct._id === product._id
                      ? " selected"
                      : "")
                  }
                >
                  {imageSources[product._id] ? (
                    <div className="product-image-wrapper">
                      <img src={product.imageData} alt="Product" width="100" />
                    </div>
                  ) : (
                    <div style={{ fontSize: "x-small" }}>
                      No image available
                    </div>
                  )}
                  <h4 className="type">{String(product.type).toUpperCase()}</h4>
                  <h4 className="price">{product.price}€/uni</h4>
                  <span rate={rates instanceof Set && rates.has(product._id) ? "true" : "false"}></span>

                  <button
                    className="admin-default-button admin-market-btn"
                    onClick={() => handleProductClick(product)}
                  >
                    Selecionar
                  </button>
                </div>
              ))}

              <div
                className="admin-product-card add-new-product"
                onClick={handleNewProduct}
              >
                <div className="add-product-content">+</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*
      <div
        className="col-12 card-table-header mt-3"
        style={{ background: "var(--focus-color)" }}
      >
        {isAddingNewProduct ? "NOVO PRODUTO" : "PRODUTO SELECIONADO"}
      </div>

      <div className="col-12 admin-card-table-body" style={{ height: showProductsGroup ? "34vh" : "74vh" }}>
        <div className="table-scroll-container">
          {selectedProduct && (
            <ProductDetail
              product={selectedProduct}
              isEditing={isEditing}
              isAddingNewProduct={isAddingNewProduct}
              editableProductData={editableProductData}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onCancel={handleCancel}
              onSave={handleSave}
              onInputChange={handleInputChange}
              setEditableProductData={setEditableProductData}
              setImagePreview={setImagePreview} 
              imagePreview={imagePreview} 
            />
          )}
          {isAddingNewProduct && (
            <ProductDetail
              product={editableProductData}
              isEditing={isEditing}
              isAddingNewProduct={isAddingNewProduct}
              editableProductData={editableProductData}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onCancel={handleCancel}
              onSave={handleSave}
              onInputChange={handleInputChange}
              setEditableProductData={setEditableProductData}
              setImagePreview={setImagePreview} 
              imagePreview={imagePreview} 
            />
          )}
          <DeleteConfirmationModal
            show={isDeleteModalOpen}
            onClose={closeDeleteConfirmationModal}
            onConfirm={handleConfirmDelete}
            title="Tem certeza que deseja excluir este produto?"
          />
          <SuccessModal
            isOpen={isSuccessModalOpen}
            onClose={closeSuccessModal}
            title="Sucesso"
            message={successMessage}
          />
        </div>
      </div>
    </div>
  );
};

const ProductDetail = ({
  product,
  isEditing,
  isAddingNewProduct,
  editableProductData,
  onDelete,
  onEdit,
  onCancel,
  onSave,
  onInputChange,
  setEditableProductData,
  setImagePreview,imagePreview
}) => {
  const handleEditChangeInside = (e) => {
    const { name, value } = e.target;
    console.log(value);
    onInputChange(name, value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Exibir a imagem selecionada
        const base64Image = reader.result;
        console.log(base64Image);
        setImagePreview(base64Image);
      };
      reader.readAsDataURL(file);
      // Atualizar o estado com os dados da imagem
      onInputChange("imageData", file); // Corrigido para chamar onInputChange diretamente com o nome do campo e o valor
    }
  };
  

  const base64ToImageUrl = (base64String, format) => {
    console.log("imagem ,",`${base64String}`)
    return `${base64String}`;
  };
  
 

  return (
    <div className="col-12 product-detail-card" style={{ padding: "1.5rem" }}>
      <div className="col-4 product-imageData-container">
      
        {product.imageData ? (

<img src={imagePreview} alt="Imagem selecionada"  width={"50%"}/>

        ) : (
          <form encType="multipart/form-data">
            <div>
              <input
                type="file"
                onChange={handleImageChange}
                accept="image/*"
              />
            </div>
          </form>
        )}
      </div>
      <div className="col-6 product-info-container">
        <div className="market-input-group">
          <label>Type</label>
          <input
            type="text"
            name="type"
            value={editableProductData.type}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          ></input>
        </div>
        <div className="market-input-group">
          <label>Description</label>
          <textarea
            name="description"
            value={editableProductData.description}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          ></textarea>
        </div>
        <div className="market-input-group">
          <label>Package Type</label>
          <input
            type="text"
            name="packageType"
            value={editableProductData.packageType}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          />
        </div>
        <div className="market-input-group">
          <label>Price</label>
          <input
            type="number"
            name="price"
            value={editableProductData.price}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          />
        </div>
      
      <div className="market-input-group">
          <label>Weight</label>
          <input
            type="number"
            name="weight"
            value={editableProductData.weight}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          />
        </div>

        <div className="market-input-group">
          <label>certification</label>
          <input
            type="text"
            name="certification"
            value={editableProductData.certification}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          />
        </div>


        <div className="market-input-group">
          <label>Nutritional Value</label>
          <input
            type="text"
            name="nutritionalValue"
            value={editableProductData.nutritionalValue}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          />
        </div>

        <div className="market-input-group">
          <label>origin</label>
          <input
            type="text"
            name="origin"
            value={editableProductData.origin}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          />
        </div>


        <div className="market-input-group">
          <label>prv_pvp</label>
          <input
            type="text"
            name="prv_pvp"
            value={editableProductData.prv_pvp}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          />
        </div>
        
        <div className="market-input-group">
          <label>  stock
</label>
          <input
            type="Number"
            name="stock"
            value={editableProductData.stock}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          />
        </div>

        <div className="market-input-group">
          <label>EAN_13</label>
          <input
            type="text"
            name="EAN_13"
            value={editableProductData.EAN_13}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          />
        </div>

        <div className="market-input-group">
          <label>ITF_14</label>
          <input
            type="text"
            name="ITF_14"
            value={editableProductData.ITF_14}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          />
        </div>
        <div className="market-input-group">
          <label>carbonFootprint</label>
          <input
            type="text"
            name="carbonFootprint"
            value={editableProductData.carbonFootprint}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          />
        </div>

        <div className="market-input-group">
          <label>expirationDate</label>
          <input
            type="text"
            name="expirationDate"
            value={editableProductData.expirationDate}
            disabled={!isEditing}
            onChange={handleEditChangeInside}
          />
        </div>


      </div>

      <div className="col-1 product-action-buttons">
        {!isAddingNewProduct && (
          <button onClick={onEdit} disabled={isEditing}>
            Editar
          </button>
        )}
        {!isAddingNewProduct && (
          <button onClick={onDelete} disabled={isEditing}>
            Eliminar
          </button>
        )}
        <button className="btn-cancel" onClick={onCancel} disabled={!isEditing}>
          Cancelar
        </button>
        <button className="btn-save" onClick={onSave} disabled={!isEditing}>
          Guardar
        </button>
      </div>*/}
    </div>
  );
};

export default MarketAdmin;
