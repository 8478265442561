import React, { useContext, useEffect, useState } from "react";
import { useFilter } from "../../FilterContext";
import Map from "./Map/Map";
import "./Dashboard.css";
import { URL } from "../../config";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import {MdMoreHoriz} from "react-icons/md"
import Spinner from "../../assets/WeblandSpinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { AuthContext } from "../AuthContext";
import "./Produtores.css"
import DeleteConfirmationModal from "./DeleteModal";




const Clientes = () => {
  const { filter } = useFilter();
  const [isLoading, setIsLoading] = useState(true);
  const [ClientesData, setClientesData] = useState([]);
  const [filteredClientesData, setFilteredClientesData] = useState([]);
  const [currentPagecliente, setCurrentPagecliente] = useState(1);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [userToDelete, setUserToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {token} = useContext(AuthContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const history = useHistory();

  const handleRowClick = (index, cliente) => {
    setSelectedRow(index);
    setSelectedRowData(cliente);
  };

  const handleMouseEnter = (index) => {
    // Handle mouse enter event, e.g., change row color on hover
    // Implement your own logic based on your requirements
  };

  const handleMouseLeave = () => {
    // Handle mouse leave event, e.g., reset row color on hover out
    // Implement your own logic based on your requirements
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      const requestData = {
        _id: userToDelete._id,
        email: userToDelete.email,
        role:"client",
        
        // Adicione outros dados que deseja enviar aqui
      };
      console.log("o que recebo",userToDelete);
  
      fetch(`${URL}/webland-api/user/deleteUser`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      })
        .then((response) => {
          if (response.status === 200) {
            console.log("Usuário excluído com sucesso",requestData);
            window.location.reload();
          } else {
            console.error("Erro ao excluir o usuário",requestData);
          }
        })
        .catch((error) => {
          console.error("Erro na solicitação para excluir o usuário:", error);
        });
    }
    setShowDeleteModal(false);
    
  };
  const handleCancelDelete = () => {
    // Feche o modal sem realizar a exclusão.
    setShowDeleteModal(false);
  };



  const SelectedRowInfo = () => {
    const {
      _id,
      address,
      bolotaSpecies,
      createdAt,
      email,
      lat,
      long,
      name,
      nif,
      phoneNumber,
      quantity,
      orderCount,
      region,
      stockBolota,
      city,
      type,
      updatedAt,
      warehouseHistory,
    } = selectedRowData;
  
   
    return (
        <div className="data-produtores-container">
      
        
         <div className="col-12 selected-row-data">
        <div className="col-3"><strong>Nome:</strong> <div>{name? name: " - "}</div></div>
        <div className="col-3"><strong>Email:</strong> <div>{email ? email:" - "}</div></div>
        <div className="col-3"><strong>Contacto:</strong> <div>{phoneNumber? phoneNumber: " - "}</div></div>
        <div className="col-3"><strong>Morada:</strong><div> {city ? city: " - "}</div></div>
        </div>
        <div className="col-12 selected-row-data mt-2 mb-3">
        <div className="col-3"><strong>Região:</strong><div> {region ? region : " - "}</div></div>
        <div className="col-3"><strong>NIF:</strong> <div>{ nif ? nif :" - "}</div></div>
        <div className="col-3"><strong>Latitude:</strong><div> {lat ? lat : " - "}</div></div>
        <div className="col-3"><strong>Longitude:</strong> <div>{long ? long : " - "}</div></div>
        </div>
        <div></div>
        <div className="col-12 selected-row-data"></div>
        <div className="col-3"><strong>Encomendas:</strong> {orderCount? orderCount : 0}</div>
        
    {/*   <div className="col-3"><strong>Type:</strong> {type ? type : " - "}</div>*/}
        <div className="col-12 mt-2">
          <button className="erase-btn"  onClick={() => handleDeleteClick(selectedRowData)} disabled={ selectedRowData._id  ? false : true}>Apagar</button>
          <button className="edit-btn" onClick={() => history.push(`/editusers?id=${selectedRowData._id}&role=client`)}  disabled={ selectedRowData._id   ? false : true} >Editar</button>
          <button 
            className="metric-btn"
            disabled={ selectedRowData._id  ? false : true}
            onClick={() => history.push(`/dashboard/metrics/client?id=${selectedRowData._id}`)}>
              Métricas
          </button>
        </div>
        <DeleteConfirmationModal
          show={showDeleteModal}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete} />
      </div>
     
    );
  };

  // Custom hook for debouncing values
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

  

  const [nameFilter, setNameFilter] = useState("");
  const [localFilter, setLocalFilter] = useState("");
  const [zonaFilter, setZonaFilter] = useState("");
  const [tipoFilter, setTipoFilter] = useState("");

  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  const handleLocalFilterChange = (event) => {
    setLocalFilter(event.target.value);
  };

  const handleZonaFilterChange = (event) => {
    setZonaFilter(event.target.value);
  };

  const handleTipoFilterChange = (event) => {
    setTipoFilter(event.target.value);
  };

  const handleCleanFilters = () => {
    setNameFilter("");
    setLocalFilter("");
    setZonaFilter("");
    setTipoFilter("");
    setSelectedRowData("");
    setSelectedRow("")
  };

  const handleApplyFilters = () => {
    applyFilters();
  };

  const itemsPerPage = 6;

  const debouncedNameFilter = useDebounce(nameFilter, 500); // 500ms delay
const debouncedLocalFilter = useDebounce(localFilter, 500);
const debouncedZonaFilter = useDebounce(zonaFilter, 500);
const debouncedTipoFilter = useDebounce(tipoFilter, 500);

useEffect(() => {
  if (isLoading) {
    fetchClientesData();
  }
  applyFilters();
}, [debouncedNameFilter, debouncedLocalFilter, debouncedZonaFilter, debouncedTipoFilter]);


useEffect(() => {
  console.log("filtro",typeof(filter) === typeof("String"));
  if (typeof(filter) === typeof("String")) {
  console.log("filtro",filter.split("/"));
  setZonaFilter(filter.split("/")[3]);
 // setTipoFilter(filter.split("/")[2]);
  } else applyFilters();
}, [filter]);

const fetchClientesData = async () => {
    try {
        const response = await fetch(`${URL}/webland-api/client/all`, {
            headers: {
                Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            },
        });
        const data = await response.json();

        // Initialize clients with type and prepare for additional orderCount property
        const clientsWithExtras = data.map(cliente => ({
            ...cliente,
            type: "cliente",
            orderCount: 0, // Initialize orderCount, will be updated later
        })).sort((a, b) => a.name.localeCompare(b.name));

        // Loop to fetch orders for each client and update orderCount
        for (const element of clientsWithExtras) {
            console.log(element._id);
            try {
                const orderResponse = await fetch(`${URL}/webland-api/order/orders/${element._id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const orders = await orderResponse.json();
                element.orderCount = orders.length; // Update the orderCount for each client
                console.log(orders);
            } catch (orderError) {
                console.error("Error fetching order data:", orderError);
            }
        }

        // Update state with enriched clients data
        setClientesData(clientsWithExtras);
        setFilteredClientesData(clientsWithExtras);
        setIsLoading(false);
    } catch (error) {
        console.error("Error fetching client data:", error);
    }
};
const createIndustryButtons = (industry) => {
  const allIndustries = [
    "alimentar",
    "restauracao",
    "padariapastelaria",
    "industriaquimica",
    "industriaalimentar",
    "insdustriabiomassa",
  ];
  const industryMapping = {
    "alimentar": "A", 
    "restauracao": "R", 
    "industriaquimica": "Q", 
    "industriaalimentar": "I", 
    "padariapastelaria": "P", 
    "insdustriabiomassa": "B"
  };
  const industryColors = {
    "alimentar": "var(--secondary-color)",
    "restauracao": "var(--secondary-color)",
    "industriaquimica": "var(--secondary-color)",
    "industriaalimentar": "var(--secondary-color)",
    "padariapastelaria": "var(--secondary-color)",
    "insdustriabiomassa": "var(--secondary-color)",
    "default": "lightgray",
  };
     
  const normalizeString = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z]/g, '').toLowerCase();

  
  const industryArray = industry?.map(normalizeString);
    //console.log(industryArray);

  return allIndustries.map(industryKey => {
    const normalizedIndustryKey = normalizeString(industryKey);
    const letter = industryMapping[industryKey];
    const isPresent = industryArray?.some(sp => sp === normalizedIndustryKey);
    const color = isPresent ? industryColors[industryKey] : industryColors["default"];

    return (
      <span key={industryKey} className="species-span" style={{ backgroundColor: color }}>
        {letter}
      </span>
    );
  });
};


  

  const applyFilters = () => {
 
    let filteredData = ClientesData;

    if (nameFilter) {
      filteredData = filteredData?.filter((item) =>
      item.name && item.name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    if (localFilter) {
      filteredData = filteredData?.filter((item) =>
      item.city && item.city.toLowerCase().includes(localFilter.toLowerCase())
      );
    }

    if (zonaFilter) {
      filteredData = filteredData?.filter((item) =>
      item.region && item.region.toLowerCase().includes(zonaFilter.toLowerCase())
      );
    }

    if (tipoFilter) {
      filteredData = filteredData?.filter((item) =>
        item.category &&
        item.category.some((cat) => 
          cat.toLowerCase().includes(tipoFilter.toLowerCase())
        )
      );
    }
    

    setFilteredClientesData(filteredData);

   
  };

  const startClientesIdx = (currentPagecliente - 1) * itemsPerPage;
  const endClientesIdx = Math.min(
    startClientesIdx + itemsPerPage,
    ClientesData.length
  );

  return (
    <div className="col-12 row">
      <div className="col-xl-4 col-md-12 col-sm-12 map-container">
        {isLoading ? (
          <div className="col-12 spinner-container">
            <Spinner />
          </div>
        ) : (
          <Map locations={filteredClientesData} selectedMarker = {selectedRowData}/>
        )}
      </div>

      <div className="col-xl-8 col-md-12 col-sm-12 tables-container-wrapper">
        <div className="tables-container">
         
          <div className="card-table" style={{background:"white"}}>
            <div
              className="card-table-header"
              style={{ background: "white" }}
            >
              <div
                className="col-1 card-table-button"
                style={{ color: "var(--primary-color)" }}
              ></div>
              <div className="col-10 card-table-title"></div>
              <div className="col-1 card-table-button">
                {/* <button onClick={handleApplyFilters}>Apply</button> */}
              </div>
            </div>
           
              <div className="card-table-body">
                <div className="col-11 card-table-body-filter">
                  <div
                    className="col-xl-1 col-md-2 col-sm-2"
                    style={{ textAlign: "left" }}
                  >
                    Filtros
                  </div>
                  <div className="col-xl-2 col-md-2 col-sm-6">
                    <input
                      type="text"
                      value={nameFilter}
                      onChange={handleNameFilterChange}
                      placeholder="Nome"
                      style={{ padding: "0.15rem 0.5rem" }}
                    />
                  </div>
                  <div className="col-xl-2 col-md-2 col-sm-6">
                    <input
                      type="text"
                      value={localFilter}
                      onChange={handleLocalFilterChange}
                      placeholder="Localidade"
                      style={{
                        padding: "0.15rem 0.5rem",
                        marginLeft: "0.5rem",
                      }}
                    />
                  </div>
                  <div
                    className="col-xl-1 col-md-2 col-sm-6"
                    style={{ width: "5%" }}
                  >
                    <select
                      value={zonaFilter}
                      onChange={handleZonaFilterChange}
                      style={{ marginLeft: "0.5rem" }}
                    >
                      <option value="">Zona</option>
                      <option value="Norte">Norte</option>
                      <option value="Centro">Centro</option>
                      <option value="Sul">Sul</option>
                    </select>
                  </div>
                  <div className="col-xl-1 col-md-2 col-sm-6">
                    <select
                      value={tipoFilter}
                      onChange={handleTipoFilterChange}
                    >
                      <option value="">Atividade</option>
                      <option value="Alimentar">Alimentar</option>
                      <option value="Industria_Quimica">Química</option>
                      <option value="Retalho">Retalho</option>
                      <option value="Restauração">Restauração</option>
                      <option value="Padaria">Padaria</option>
                      <option value="Biomassa">Biomassa</option>
                    </select>
                  </div>
                  <div className="col-xl-1 col-md-2 col-sm-6">
                    <button
                      className="filter-clean-button"
                      onClick={handleCleanFilters}
                    >
                      Limpar
                    </button>
                  </div>
                </div>
              </div>
          
          </div>
          <div className="card-table" style={{background:"white"}}>
            <div className="card-table-header">
              <div
                className="col-2 card-table-button"
                style={{ justifyContent: "left" }}
              >
                <button
                  className="previous-btn"
                  onClick={() =>
                    setCurrentPagecliente((prevPage) =>
                      Math.max(prevPage - 1, 1)
                    )
                  }
                  disabled={currentPagecliente === 1}
                >
                  <AiOutlineLeft size={12} />
                </button>
                <span>{currentPagecliente}</span>
                <button
                  className="next-btn"
                  onClick={() =>
                    setCurrentPagecliente((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(filteredClientesData.length / itemsPerPage)
                      )
                    )
                  }
                  disabled={endClientesIdx >= filteredClientesData.length}
                >
                  <AiOutlineRight size={12} />
                </button>
              </div>
              <div className="col-8 card-table-title">Clientes</div>
              <div className="col-2 card-table-button">
                {/* <button className="card-table-button-add"> <MdMoreHoriz /></button> */}
              </div>
            </div>
            <div className="card-table-body" style={{height:"39vh", justifyContent:"start"}}>
            <table className="dashboard-user-table" style={{borderCollapse:"collapse", margin:"0.1rem 0rem"}}>
  <thead>
    <tr style={{fontSize:"small"}}>
      <th >Nome</th>
      <th >Localidade</th>
      <th >Zona</th>
      <th >Setor Atividade</th>
      <th >Encomendas</th>
   
    </tr>
  </thead>
  <tbody>
    {isLoading ? (
      <div className="col-12 spinner-container">
        <Spinner />
      </div>
    ) : (
      filteredClientesData
        .slice(startClientesIdx, endClientesIdx)
        .map((cliente, index) => (
          <tr key={index}
            className={`${selectedRow === index ? 'tr-producer selected-row table-row-height-45' : 'tr-Clientes table-row-height-45'}`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => handleRowClick(index, cliente)}
            style={{ borderBottom:"2px solid white"}}
          >
            <td className="" style={{ cursor: "pointer" }}>{cliente.name}</td>
            <td className="table-cell" style={{ cursor: "pointer" }}>{cliente.city}</td>
            <td className="table-cell" style={{ cursor: "pointer" }}>{cliente.region}</td>
            <td className="producer-category-wrapper" style={{ cursor: "pointer" }}>{createIndustryButtons(cliente.category)}</td>
            <td className="table-cell stock-cell" style={{marginBottom:"0.5rem",  cursor: "pointer" }}>
              {cliente.orderCount ?  cliente.orderCount : 0}
            </td>
          </tr>
        ))
    )}
  </tbody>
</table>

            </div>
          </div>
        </div>
        {" "}
        {
          selectedRow!== null && (
          <div className="card-table mt-1" style={{background:"white"}}>
            <div
              className="card-table-header"
              style={{ background: "white" }}
            >
              <div
                className="col-1 card-table-button"
                style={{ color: "var(--primary-color)" }}
              ></div>
              <div className="col-10 card-table-title"></div>
              <div className="col-1 card-table-button">
                {/* <button onClick={handleApplyFilters}>Apply</button> */}
              </div>
            </div>
           
              <div className="card-table-body" style={{margin:0, padding:0}}>
              
                  {SelectedRowInfo()}
                 
                 
             
              </div>
          
          </div>
                      )
}
      </div>
    </div>
  );
};

export default Clientes;
