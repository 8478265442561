import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addProduct } from "../../redux/basketSlice";
import AddItemModal from "./AddItemModal";
import Spinner from "../../assets/WeblandSpinner";
import { AuthContext } from "../AuthContext"; // Certifique-se de que esse caminho esteja correto
import { URL } from "../../config"; // Certifique-se de que o URL esteja correto
import "./ProductDetailPage.css";
import { FaArrowLeft } from "react-icons/fa";

const ProductDetailPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null); // Apenas um produto
  const [quantity, setQuantity] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Adicionando estado de loading
  const { token } = useContext(AuthContext); // Pegar token do contexto de autenticação

  // Pegar o productId da URL
  const urlParams = new URLSearchParams(window.location.search);
  const productId = urlParams.get("id");
  

  const fetchProductDetails = async () => {
    setIsLoading(true); // Iniciar loading
    try {
      const response = await fetch(`${URL}/webland-api/product/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch product details");
      }

      const data = await response.json();
      setProduct(data);
    } catch (error) {
      console.error("Error fetching product details:", error);
    } finally {
      setIsLoading(false); // Encerrar loading
    }
  };

  useEffect(() => {
    fetchProductDetails();
    console.log("productId",productId)
  }, [productId]);

  // Retorno condicional para o estado de loading
  if (isLoading) {
    return (
      <div className="market-container-center">
        <Spinner />
        <p>Carregando detalhes do produto...</p>
      </div>
    );
  }

  // Caso o produto não seja encontrado
  if (!product) {
    return (
      <div className="market-container-center">
        <p>Produto não encontrado.</p>
        <button onClick={() => history.goBack()}>Voltar</button>
      </div>
    );
  }

  const handleAddToBasket = () => {
    const productWithQuantity = { ...product, quantity };
    dispatch(addProduct(productWithQuantity));
    setShowModal(true);
  };

  const increaseQuantity = () => setQuantity(quantity + 1);
  const decreaseQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  return (
    <div className="col-12 product-detail-page">
    <div className="market-title-wrapper" style={{ fontSize: "22px", display: "flex", alignItems: "center" }}>
      {/* Ícone de seta para voltar */}
      <FaArrowLeft 
        style={{ cursor: "pointer", marginRight: "10px" }}
        onClick={() => history.goBack()}
      />
      {product.type.toUpperCase()}
    </div>
    <img
                src={product.imageData}
                alt={product.type}
                style={{
                  width: "30%",
                  height: "30%",
                  objectFit: "cover",
                  marginBottom: "1rem",
                }}
              />
    <div className="market-product-wrapper">
      <p className="market-description">{product.description}</p>
      <p className="market-package">Embalagem: {product.packageType}</p>
      <p className="market-dimensions">Dimensão: {product.dimensions}</p>
      <p className="market-price">
        Preço: <b>{product.price}€/uni</b>
      </p>
      <p>Peso: {product.weight}</p>
        <p>Stock: {product.stock}</p>
        <p>Origem: {product.origin}</p>
        <p>Certificação: {product.certification}</p>
        <p>Valor Nutricional: {product.nutritionalValue}</p>
        <p>PRV PVP: {product.prv_pvp}</p>
        <p>EAN 13: {product.EAN_13}</p>
        <p>ITF 14: {product.ITF_14}</p>
        <p>Pegada de Carbono: {product.carbonFootprint}</p>
        <p>Data de Validade: {product.expirationDate}</p>

      {/* Agrupando e alinhando quantidade e botão "Adicionar" */}
      <div className="market-quantity-add-group centered-group">
        <div className="market-quantity-controls">
          <label htmlFor="market-quantity" className="market-quantity-label">Quantidade:</label>
          <div className="market-input-group">
            <input
              className="market-quantity-input"
              type="number"
              min="1"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
            />
            <div className="market-quantity-buttons2">
              <span className="market-increase-btn" onClick={increaseQuantity}>+</span>
              <span className="market-decrease-btn" onClick={decreaseQuantity}>-</span>
            </div>
          </div>
        </div>

        {/* Botão Adicionar ao lado da quantidade */}
        <button className="market-add-button2" onClick={handleAddToBasket}>
          Adicionar
        </button>
      </div>
    </div>

    {showModal && (
      <AddItemModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title={product.type.toUpperCase()}
        message={"Artigo adicionado com sucesso!"}
      />
    )}
  </div>


  );
};

export default ProductDetailPage;
