import React, { useContext, useEffect, useState } from "react";
import { URL } from "../../config";
import Spinner from "../../assets/WeblandSpinner";
import {useHistory} from "react-router-dom";
import AddBolotaModal from "../Desktop/AddBolotaDesktop";
import EditBolotaModal from "../Desktop/EditBolotaDesktop";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import {AiFillHome, AiOutlineMinusSquare} from "react-icons/ai";
import {MdOutlineAddBox, MdOutlineMinusBox,MdOutlineWarehouse, MdOutlineReadMore  } from "react-icons/md";
import { RiContactsLine } from "react-icons/ri";
import "./Metrics.css";
import { AuthContext } from "../AuthContext";



ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale
);

function ProducerMetrics() {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [produtorData, setProdutorData] = useState({});
  const [producerId, setProducerId] = useState(null);
  const [orders, setOrders] = useState([]);

  
  const [, dashboard, , type] = history.location.pathname.split("/");

  const {token} = useContext(AuthContext)
  
  

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const idFromURL = urlParams.get("id");
  
    if (idFromURL) {
      setProducerId(idFromURL);
      fetchProducerOrders(idFromURL);
      fetchProducerData(idFromURL);
    }
  }, []); 

  const fetchProducerData = async (id) => {
   await fetch(`${URL}/webland-api/admin/${id}` ,  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const produtorWithType = data.map((produtor) => ({
          ...produtor,
          type: "produtor",
          quantity: produtor.stockBolota && produtor.stockBolota.map((item) => item.quantity),
        }));
        //console.log(produtorWithType);

        setProdutorData(produtorWithType[0]);
        //setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching producer data:", error);
      });
  };

  const fetchProducerOrders = async (id) => {
    await fetch(`${URL}/webland-api/order/producer/${id}` ,  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
       
        Array.isArray(data) && setOrders(data);
        !Array.isArray(data) &&  setOrders(null);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching producer data:", error);
        setIsLoading(false);
        setOrders(null);
      });
  };


  const { stockBolota, warehouseHistory } = produtorData;

 
  return isLoading ? (
    <Spinner />
  ) : (
    <div className="producer-metrics-container">
      <Breadcrumb producerId={producerId} dashboard={dashboard} />
      <h2 style={{ textTransform: "uppercase" }}>{produtorData.name}</h2>

      <div className="row">
        <div className="col-md-4">
          <ProducerInfo produtorData={produtorData} />
        </div>
        <div className="col-md-3">
          <WarehouseStock stockBolota={stockBolota} />
        </div>
        <div className="col-md-5">
          <WarehouseHistory history={warehouseHistory} />
        </div>
      </div>
     <div className="row" style={{marginTop:"5%"}}>
        <div className="orders-history-container">
          <h5 className="card-title">Histórico Encomendas</h5>
          <div className="warehouse-history-header">
            <div className="warehouse-history-cell">Data da Encomenda</div>
            <div className="warehouse-history-cell">N. Encomenda</div>
            <div className="warehouse-history-cell">Cliente</div>
            <div className="warehouse-history-cell">Qt.</div>
            <div className="warehouse-history-cell">Estado</div>
          </div>
          <div className="warehouse-history-body">
            {orders && orders?.map((item, index) => (
              <div key={index} className="warehouse-history-item">
                <span className="warehouse-history-cell">
                  {new Date(item.orderDate).toLocaleDateString()}
                </span>
                <span className="warehouse-history-cell">{item._id}</span>
                <span className="warehouse-history-cell">{item.customer}</span>
                <span className="warehouse-history-cell">{item.totalAmount}</span>
                <span className="warehouse-history-cell">{item.status}</span>
              </div>
            ))}
            {!orders &&  <div className="warehouse-history-item" style={{justifyContent:"center", textAlign:"center", padding:"2rem 0rem"}}>O produtor ainda não possui encomendas</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

const Breadcrumb = ({ producerId, dashboard }) => {
  const history = useHistory();

  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <div className="breadcrumb-wrapper">
      {/* <span className="breadcrumb-item" onClick={() => navigateTo('/')}>
        Home
      </span>
      <span> &gt; </span> */}
      <span className="breadcrumb-item" onClick={() => navigateTo('/dashboard')}>
        {dashboard ? dashboard.toUpperCase() : 'DASHBOARD'}
      </span>
      <span> &gt; </span>
      <span className="breadcrumb-item" onClick={() => navigateTo('/dashboard/produtores')}>
        Produtores
      </span>
      <span> &gt; </span>
      <span className="breadcrumb-item">
        {producerId}
      </span>
    </div>
  );
};

const ProducerInfo = ({ produtorData }) => {
  const { nif, email, phoneNumber, address,street,city, region, lat, long, createdAt } = produtorData;

  return (
    <div className="card producer-info-card">
      <div className="card-body">
        <h5 className="card-title"> Informação do Produtor</h5>
        <p><strong>NIF:</strong> {nif}</p>
        <p><strong>Email:</strong> {email}</p>
        <p><strong>Telefone:</strong> {phoneNumber}</p>
        <p><strong>Morada:</strong> {street}, {city}</p>
        <p><strong>Zona:</strong> {region}</p>
        <p><strong>Latitude:</strong> {lat}</p>
        <p><strong>Longitude:</strong> {long}</p>
        <p><strong>Data de criação:</strong> {new Date(createdAt).toLocaleDateString()}</p>
      </div>
    </div>
  );
};


const WarehouseStock = ({ stockBolota }) => {
  const chartStockData = {
    labels: stockBolota && stockBolota.map(item => item.acornType),
    datasets: [{
      data: stockBolota && stockBolota.map(item => item.quantity),
      backgroundColor: ['#ffa500', '#5d643f', '#8e9475'],
    }],
  };

  function StockBolotaList ({ stockBolota })  {
    return (
      <div className="stock-bolota-container">
        <div className="stock-bolota-header">
          <span className="stock-bolota-header-cell">Espécie</span>
          <span className="stock-bolota-header-cell">Quantidade</span>
          <span className="stock-bolota-header-cell">Preço</span>
        </div>
        {stockBolota && stockBolota.map((item, index) => (
          <div key={index} className="stock-bolota-item">
            <span className="stock-bolota-cell">{item.acornType}</span>
            <span className="stock-bolota-cell">{item.quantity}kg</span>
            <span className="stock-bolota-cell">{item.price}€</span>
          </div>
        ))}
      </div>
    );
  };
  
 
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const closeEditBolotaModal = () => {
    setShowModal(false);
  };

  const closeAddBolotaModal = () => {
    setShowModal(false);
  };


  return (
    <div className="card warehouse-stock-card">
      <div className="card-body" style={{height:"32vh"}}>
        <h5 className="card-title">Stock Armazém</h5>
       <StockBolotaList stockBolota={stockBolota} />
        <Doughnut data={chartStockData} style={{margin:"0.1rem 2.5rem"}}/>
        <br/>
        <div style={{justifyContent:"space-between", display:"flex"}}>

        <button className="col-5 add-btn" style={{background:"var(--focus-color)"}} onClick={()=> setShowModal(true)}>Adicionar</button>
        <button className="col-5 add-btn" onClick={()=> setShowModalEdit(true)}>Alterar</button>
        </div>
      </div>
      <AddBolotaModal isOpen={showModal} onClose={() => setShowModal(false)} onAdd={closeAddBolotaModal}/>
      <EditBolotaModal isOpen={showModalEdit} onClose={() => setShowModalEdit(false)} onAdd={closeEditBolotaModal}/>

    </div>
  );
};


const WarehouseHistory = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  
  // Sort the history in descending order by date
  const sortedHistory = history ? [...history].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) : null;

  
  return (
    <div className="card warehouse-stock-card">
    <div className="card-body">
    <div className="warehouse-history-container">
      <h5 className="card-title">Histórico Armazém</h5>
      <div className="warehouse-history-header">
        <div className="warehouse-history-cell">Date</div>
        <div className="warehouse-history-cell">Espécie</div>
        <div className="warehouse-history-cell">Qt.</div>
        <div className="warehouse-history-cell">Mov.</div>
      </div>
      <div className="warehouse-history-body">

      {sortedHistory && sortedHistory.map((item, index) => (
        <div key={index} className="warehouse-history-item">
    <span className="warehouse-history-cell">{new Date(item.timestamp).toLocaleDateString()}</span>
    <span className="warehouse-history-cell">{item.acornType}</span>
    <span className="warehouse-history-cell">{item.quantity}</span>
    <span className="warehouse-history-cell">
      {item.transactionType === "IN" ? (
        <MdOutlineAddBox color="#43806c" />
        ) : (
          <AiOutlineMinusSquare color="#ad1126" />
          )}
    </span>
  </div>
))}
{!sortedHistory?.length >0 &&  <div className="warehouse-history-item" style={{justifyContent:"center", textAlign:"center", padding:"2rem 0rem"}}>O produtor ainda não possui registos de armazém</div>}
</div>

        </div>
        </div>
        </div>
  );

};



export default ProducerMetrics;
