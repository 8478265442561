import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import SuccessModal from '../../Desktop/SuccessModal';
import DeleteConfirmationModal from '../../Desktop/DeleteModal';
import "./Settings.css"
import { URL } from '../../../config';

const ManageConversionRates = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [ratesData, setRatesData] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [rates, setRates] = useState([]);
  const [form, setForm] = useState({});
  const [conversionRate, setConversionRate] = useState({
    species: '',
    mioloRaw: 0,
    mioloDry: 0,
    errorThreshold: 0
  });
  
   const { token } = useContext(AuthContext); 
   const speciesOptions = ['Azinheira', 'Carvalho', 'Sobreiro', 'Carvalho portugues'];

  const handleSpeciesChange = (event) => {
    setConversionRate({ ...conversionRate, species: event.target.value });
  };

  const handleDelete = async (productId) => {
    try {
      const response = await fetch(`${URL}/webland-api/rates/delete-rate/${productId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Error deleting product');
      }
  
      fetchData();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = (item) => {
    // Assuming you have a state for the form like this:
    // const [formState, setFormState] = useState({ species: '', mioloRaw: '', mioloDry: '', errorThreshold: '' });
  
    setForm({
      species: item.species,
      mioloRaw: item.rates.mioloRaw,
      mioloDry: item.rates.mioloDry,
      errorThreshold: item.rates.errorThreshold,
      // Include the product ID if you need it for the update API call
      productId: item.product._id,
    });

    console.log(item)
  
    // Show the form if it's hidden
    //setShowForm(true);
  };
  
  


  const fetchRates = async () => {
    try {
      const ratesResponse = await fetch(`${URL}/webland-api/rates/conversion-rate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!ratesResponse.ok) {
        throw new Error(`HTTP error! Rates status: ${ratesResponse.status}`);
      }
  
      const dataRates = await ratesResponse.json();
      
      setRates(new Set(dataRates.map(rate => rate.product._id)));  
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error state appropriately
    } 
  }

  useEffect(() => {
  
  }, [rates]);

  const fetchData = async () => {
    try {
      const ratesResponse = await fetch(`${URL}/webland-api/rates/conversion-rate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!ratesResponse.ok) {
        throw new Error(`HTTP error! Rates status: ${ratesResponse.status}`);
      }
  
      const dataRates = await ratesResponse.json();
      setRatesData(dataRates);
  
      const productsResponse = await fetch(`${URL}/webland-api/product/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!productsResponse.ok) {
        throw new Error(`HTTP error! Products status: ${productsResponse.status}`);
      }
  
      const dataProducts = await productsResponse.json(); 
  
      // Verifique e remova produtos inválidos
      const validProducts = dataProducts?.filter((product) => product && product?.type);
      setProducts(validProducts);
  
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error state appropriately
    } finally {
      setIsLoading(false);
    }
  }
  
  

  useEffect(() => {
    fetchData();
    fetchRates();
    

   
  
  }, []);

  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
    // Optionally, fetch and set the current conversion rate for this product
  };

  const handleInputChange = (event) => {
    setConversionRate({ ...conversionRate, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    
    const payload = {
        product: selectedProduct,
        ...conversionRate,
    };
    console.log(payload)
    
    await fetch(`${URL}/webland-api/rates/define-conversion-rate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Conversion rate saved:', data);
        setShowModal(true)
      })
      .catch((error) => {
        console.error("Error submitting conversion rate:", error);
        // Handle error
      })
      .finally(() => {
        fetchData()
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="conversion-rate-container mt-2">
        <form onSubmit={handleSubmit} className="col-10 conversion-rate-form">
          <div className="form-group">
            <label>Produto:</label>
            <select value={selectedProduct} onChange={handleProductChange}>
              <option value="">Selecione um produto</option>{" "}
              {products
                ?.slice() 
                ?.sort((a, b) => a?.type.localeCompare(b?.type))
                ?.map((product) => (
                  <option key={product._id} value={product._id} disabled={rates.includes(product._id)}>
                    {product?.type.toUpperCase()}
                  </option>
                ))}
            </select>

            <label htmlFor="species">Tipo de Bolota:</label>
            <select
              id="species"
              value={conversionRate.species}
              onChange={handleSpeciesChange}
            >
              <option value="">Selecione um tipo</option>
              {speciesOptions?.map((species, index) => (
                <option key={index} value={species}>
                  {species}
                </option>
              ))}
            </select>
          </div>
          <div className="settings-input-group">
            <label>
              Miolo:
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                name="mioloRaw"
                value={conversionRate.mioloRaw}
                onChange={handleInputChange}
              />
              {String(conversionRate.mioloRaw * 100).split(".")[0]}%
            </label>
            <label>
              Miolo Após Secagem:
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                name="mioloDry"
                value={conversionRate.mioloDry}
                onChange={handleInputChange}
              />
              {String(conversionRate.mioloDry * 100).split(".")[0]}%
            </label>
            <label>
              Margem Erro:
              <input
                type="range"
                min="0"
                max="1"
                step="0.05"
                name="errorThreshold"
                value={conversionRate.errorThreshold}
                onChange={handleInputChange}
              />
              {
                String(
                  parseFloat(conversionRate.errorThreshold).toFixed(2) * 100
                ).split(".")[0]
              }
              %
            </label>
          </div>
        </form>
        <div className="col-2 conversion-rate-form form-actions">
          <button
            type="submit"
            className="col-12 settings-btn-set"
            onClick={handleSubmit}
          >
            Definir
          </button>
          {/* <button type="button" className="settings-btn-edit">
            Editar
          </button>
          <button type="button" className="settings-btn-remove">
            Remover
          </button> */}
        </div>
        {showModal && (
          <SuccessModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            message={"Parâmetros definidos com sucesso"}
            title={"Conversion Rates"}
          />
        )}
      </div>
      {ratesData !== null && (
        <div className="conversion-rate-container mt-2">
          <table className="settings-table">
            <thead>
              <tr>
                <th>Produto</th>
                <th>Espécie</th>
                <th>Miolo</th>
                <th>Miolo Após Secagem</th>
                <th>Margem Erro</th>
              </tr>
            </thead>
            <tbody>
              {ratesData?.map((item) => (
                <tr key={item._id}>
                  <td>{item?.product?.type}</td>
                  <td>{item.species}</td>
                  <td>{item.rates.mioloRaw* 100}%</td>
                  <td>{item.rates.mioloDry* 100}%</td>
                  <td>
                    {parseFloat(item.rates.errorThreshold * 100)}%
                  </td>
                  <td className='action-cell'>
                    <button onClick={() => handleUpdate(item)} disabled={true}>
                      Modificar
                    </button>
                  
                    <button
                    style={{marginLeft:"0.5rem"}}
                      onClick={() => {
                        setItemToDelete(item);
                        setShowModalDelete(true);
                      }}
                    >
                      Apagar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {showModalDelete && (
            <DeleteConfirmationModal
              show={showModalDelete}
              onClose={() => {
                setShowModalDelete(false);
                //setItemToDelete(null);
              }}
              title={`Deseja eliminar a conversão para ${itemToDelete.product.type}`}
              onConfirm={() => {
                handleDelete(itemToDelete._id);
                setShowModalDelete(false);
                setItemToDelete(null);
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ManageConversionRates;
