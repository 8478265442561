import React, { useState, useEffect } from "react";
import { AiOutlineContacts } from "react-icons/ai";
import { TbMapPinCheck, TbGps } from "react-icons/tb";
import GeolocalAddress from "./GeoLocalAddress";
import { components } from "react-select";
import MySelect from "./MySelect.js";
import makeAnimated from "react-select/animated";
import { URL } from "../../config";

const NIF_VALIDATION_URL = "http://www.nif.pt/?json=1&q=509442013&key=eaefd8cc15a7bc5283f12347bc0d131e";

const RegisterProducer = ({ onProducerInfoChange }) => {
  const [isNifValid, setIsNifValid] = useState(true);
  const [isNifAvailable, setIsNifAvailable] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isToFly, setIsToFly] = useState(false);
  //const [packaging_conditions, setpackaging_conditions] = useState("");
  //const [capacity_deliver, setcapacity_deliver] = useState("");
  //const [number_deliver, setnumber_deliver] = useState('');
  //const [conditions, setconditions] = useState("");
  //const [conditions_location, setconditions_location] = useState("");
  const [optionSelected, setOptionSelected] = useState([]);
  const [optionSelectedCertifications, setoptionSelectedCertifications] = useState([]);
  const [certificates, setCertificates] = useState([]);

  const [producerInfo, setProducerInfo] = useState({
    name: "",
    nif: "",
    street: "",
    city: "",
    region: "",
    postalCode: "",
    distrito: "",
    country: "",
    phoneNumber: "",
    lat: "",
    long: "",
    packaging_conditions:"",
    capacity_deliver:"",
    number_deliver:'',
    conditions:"",
    conditions_location:"",
    species: "",
    certifications: "",
  });

  const optionsSpecies = [
    { value: 'carvalho_alvarinho', label: 'Carvalho Alvarinho (Q. robur)' },
    { value: 'carvalho_negral', label: 'Carvalho Negral (Q. pyrenaica)' },
    { value: 'carvalho_portugues', label: 'Carvalho Português (Q. faginea)' },
    { value: 'Azinheira', label: 'Azinheira (Q. rotundifolia)' },
    { value: 'Sobreiro', label: 'Sobreiro (Q. suber)' },
  ];

  const optionsCertifications=[
    { value: 'agricultura_biologica_europa', label: 'Agricultura biológica Europa' },
    { value: 'rainforest_alliance_standard', label: 'Rainforest Alliance Standard' },
    { value: 'global_gap', label: 'GLOBAL G.A.P' },
    { value: 'gestao_florestal_responsavel', label: 'Gestão florestal responsável - FSC' },
    { value: 'outra', label: 'Outra' },
  ]

  const Option = props => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'left', height: '100%', gap:10 }}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null} 
            style={{ margin: 0, padding:0 }}
          />
          <label style={{ margin: 0 }}>{props.label}</label>
        </div>
      </components.Option>
    );
  };
  

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const animatedComponents = makeAnimated();

  const handleProducerInfoChange = (info) => {
    console.log("info", info)
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      ...info
    }));
  };
  const handlepackaging_conditionsChange = (e) => {
    const { value } = e.target;
    
    // Atualizar o estado do producerInfo para incluir a alteração no number_deliver
    setProducerInfo({
      ...producerInfo,
      packaging_conditions: value,})
   
   
  };
  const handlecapacity_deliverChange = (e) => {
    const { value } = e.target;
    
    // Atualizar o estado do producerInfo para incluir a alteração no number_deliver
    setProducerInfo({
      ...producerInfo,
      capacity_deliver: value,})
   
  };
  const handlenumber_deliverChange = (e) => {
    const { value } = e.target;
    
    // Atualizar o estado do producerInfo para incluir a alteração no number_deliver
    setProducerInfo({
      ...producerInfo,
      number_deliver: value, // Atualizando o campo number_deliver
    });
  };
  
  const handleconditionsChange = (e) => {
    const { value } = e.target;
    
    // Atualizar o estado do producerInfo para incluir a alteração no number_deliver
    setProducerInfo({
      ...producerInfo,
      conditions: value,})
   
   
  };
  const handleconditions_locationChange = (e) => {
    const { value } = e.target;
    
    // Atualizar o estado do producerInfo para incluir a alteração no number_deliver
    setProducerInfo({
      ...producerInfo,
      conditions_location: value,})
      
  };
  


  const handleBasicInfoChange = (e) => {
    const { name, value } = e.target;

    if (name === "nif") {
      const isValidNif = /^\d{9}$/.test(value);
      setIsNifValid(isValidNif);
    }

    if (name === "phoneNumber") {
      const isValidPhoneNumber = /^\d{9}$/.test(parseInt(value));
      setIsValidPhone(isValidPhoneNumber);
    }

    setProducerInfo(prevInfo => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleAddressChange = (field, value) => {
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      [field]: value,
    }));
  };

  const handleSpecies = selected => {
    setOptionSelected(selected);
    const selectedSpecies = selected.map(item => item.value);
   // const speciesString = selectedSpecies.join(", "); // Convertendo o array em uma string separada por vírgulas
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      species: selectedSpecies, // Salvando a string no estado
    }));
  };

  const handleCertifications = selected => {
    setoptionSelectedCertifications(selected);
    const selectedCertifications = selected.map(item => item.value);
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      certifications: selectedCertifications,
    }));
  };
  
  
  
/*
  const handleCertifications = (e) => {
    setProducerInfo(prevInfo => ({
      ...prevInfo,
      certifications: e.target.value,
    }));
  };*/

  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        const response = await fetch(`${URL}/webland-api/certificates/get_certificates`);
        if (!response.ok) {
          throw new Error('Erro ao buscar os certificados');
        }
        const data = await response.json();
      // Filtrar certificados onde o type é "produtor"
      const producerCertNames = data
          .filter(cert => cert.type === "produtor")
          .map(cert => ({ value: cert.name, label: cert.name }));
        setCertificates(producerCertNames);
        console.log("certificados",data,producerCertNames);
      } catch (error) {
        console.error("Erro ao buscar os certificados:", error.message);
      }
    };

    fetchCertificates();
    onProducerInfoChange(producerInfo);
  }, [producerInfo, onProducerInfoChange]);

  return (
    <div className="producer-info">
      <div className="title-register-wrapper">
        <AiOutlineContacts
          size={35}
          style={{
            marginRight: "1rem",
            position: "relative",
            top: "0.7rem",
            color: "var(--primary-color)",
          }}
        />
        Contactos
      </div>
      <br />

      <div className="form-group">
        <label htmlFor="name">Nome / Empresa:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={producerInfo.name}
          onChange={handleBasicInfoChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="nif">
          NIF:{" "}
          {isNifAvailable && isNifValid && producerInfo.nif !== "" ? (
            <span className="availability-icon" style={{ color: "green" }}>
              &#x2713;
            </span>
          ) : (
            producerInfo.nif !== "" && (
              <span className="availability-icon" style={{ color: "red" }}>
                &#x2717;
              </span>
            )
          )}
        </label>
        <input
          type="text"
          id="nif"
          name="nif"
          value={producerInfo.nif}
          onChange={handleBasicInfoChange}
          className="form-control"
        />
      </div>
      {/* <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={producerInfo.email}
          onChange={handleInputChange}
          className="form-control"
        />
      </div> */}
      <div className="form-group">
        <label htmlFor="phoneNumber">Telefone:{isValidPhone && producerInfo.phoneNumber !== "" ? (
            <span className="availability-icon" style={{ color: "green" }}>
              &#x2713;
            </span>
          ) : (
            producerInfo.phoneNumber !== "" && (
              <span className="availability-icon" style={{ color: "red" }}>
                &#x2717;
              </span>
            )
          )}</label>
        <input
          type="text"
          id="phoneNumber"
          name="phoneNumber"
          value={producerInfo.phoneNumber}
          onChange={handleBasicInfoChange}
          className="form-control"
        />
        
      </div>

      <div className="col-xl-12 col-md-4 col-sm-12 form-group">
            <label htmlFor="packaging_conditions">Condições de Embalamento:</label>
            <select
              id="packaging_conditions"
              name="packaging_conditions"
              required
              className="form-control"
              onChange={handlepackaging_conditionsChange}
              defaultValue="" // Set default value to empty
            >
              <option value="" disabled hidden>
                Selecione
              </option>
              <option value="sacos_de_rafia/serapilheira">Sacos de ráfia/serapilheira</option>
              <option
                value="palotes"
                //disabled={true}
                //style={{ color: "lightgray" }}
              >
                Palotes
              </option>
              <option
                value="paletes"
                //disabled={true}
               // style={{ color: "lightgray" }}
              >
                Paletes
              </option>
            </select>
          </div>
          <div className="col-xl-12 col-md-4 col-sm-12 form-group">
            <label htmlFor="conditions">Condições de Carga:</label>
            <select
              id="conditions"
              name="conditions"
              required
              className="form-control"
              onChange={handleconditionsChange}
              defaultValue="" // Set default value to empty
            >
              <option value="" disabled hidden>
                Selecione
              </option>
              <option value="cais">Cais</option>
              <option
                value="porta_paletes"
                //disabled={true}
                //style={{ color: "lightgray" }}
              >
                Porta paletes
              </option>
              <option
                value="monta-cargas"
                //disabled={true}
               // style={{ color: "lightgray" }}
              >
                Monta-cargas
              </option>
              <option
                value="nenhum"
                //disabled={true}
               // style={{ color: "lightgray" }}
              >
                Nenhum
              </option>
            </select>
          </div>


          <div className="col-xl-12 col-md-4 col-sm-12 form-group">
            <label htmlFor="conditions_location">Condições de acesso ao local de carga:</label>
            <select
              id="conditions_location"
              name="conditions_location"
              required
              className="form-control"
              onChange={handleconditions_locationChange}
              defaultValue="" // Set default value to empty
            >
              <option value="" disabled hidden>
                Selecione
              </option>
              <option value="Permite o acesso a camião TIR">Permite o acesso a camião TIR</option>
              <option
                value="Permite o acesso a pesados até 3500 Kg"
                //disabled={true}
                //style={{ color: "lightgray" }}
              >
              Permite o acesso a pesados até 3500 Kg
              </option>
              <option
                value="Permite o acesso a ligeiros até 1500 kg"
                //disabled={true}
               // style={{ color: "lightgray" }}
              >
                Permite o acesso a ligeiros até 1500 kg
              </option>
             
            </select>
          </div>

          <div className="col-xl-12 col-md-4 col-sm-12 form-group">
            <label htmlFor="capacity_deliver">Tem capacidade para entregar bolota num ponto de recolha?</label>
            <select
              id="capacity_deliver"
              name="capacity_deliver"
              required
              className="form-control"
              onChange={handlecapacity_deliverChange}
              defaultValue="" // Set default value to empty
            >
              <option value="" disabled hidden>
                Selecione
              </option>
              <option value="sim">Sim</option>
              <option
                value="nao"
                //disabled={true}
                //style={{ color: "lightgray" }}
              >
              Não
              </option>
            
             
            </select>
          </div>
          <div className="form-group">
        <label htmlFor="number_deliver">Até quantos km se desloca? </label>
        <input
          type="number"
          id="number_deliver"
          name="number_deliver"
          value={producerInfo.number_deliver}
          onChange={handlenumber_deliverChange}
          className="form-control"
        />
      </div>


      <div className="col-xl-12 col-md-12 col-sm-12 form-group">
      Espécies:
       </div> 
       <div  className="col-xl-6 " style={{  paddingLeft:"0rem"}}>
        <MySelect required
          options={optionsSpecies}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option, MultiValue, animatedComponents }}

          onChange={handleSpecies}
          allowSelectAll={true}
          value={optionSelected}
          />
        </div>
        <div className="col-xl-6 ">
            <label htmlFor="role">Certificações:</label>
            <MySelect required
                options={certificates}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}

                onChange={handleCertifications}
                allowSelectAll={true}
                value={optionSelectedCertifications}
                />
          </div>

      <div className="title-register-wrapper mt-3 mb-2">
        <TbMapPinCheck
          size={35}
          style={{
            marginRight: "1rem",
            position: "relative",
            top: "0.7rem",
            color: "var(--primary-color)",
          }}
        />
        Morada
      </div>
      <div className="form-group">
        <h5 style={{ color: "var(--secondary-color)" }}>
          Use o mapa para definir as coordenadas GPS:
        {/* <span  
        className="gps-pin-location-button" 
        onClick={() => setIsToFly(prevIsToFly => !prevIsToFly)}
        style={{background: !isToFly && "lightgrey"}}
        >
          <TbGps size={25} color="white"/>
        </span> */}
        </h5>
        <h6 style={{color:"var(--secondary-color)"}}>Clique no marcador para preenchimento automático da morada</h6>
        <GeolocalAddress onProducerInfoChange={handleProducerInfoChange} flyToMyLocation={true}/>
      </div>

      

      <div className="gps-group-wrapper">
        <div className="form-group">
          <label htmlFor="lat">Latitude:</label>
          <input
            type="text"
            id="lat"
            name="lat"
            value={producerInfo.lat}
            onChange={(e) => handleAddressChange("lat", e.target.value)}
            className="form-control"
            //disabled={true}
            style={{background:"white"}}
          />
        </div>

        <div className="form-group" style={{ marginLeft: "0.5rem" }}>
          <label htmlFor="long">Longitude:</label>
          <input
            type="text"
            id="long"
            name="long"
            value={producerInfo.long}
            onChange={(e) => handleAddressChange("long", e.target.value)}
            className="form-control"
            //disabled={true}
            style={{background:"white"}}
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="street">Rua:</label>
        <input
          type="text"
          id="street"
          name="street"
          value={producerInfo.street}
          onChange={(e) => handleAddressChange("street", e.target.value)}
          className="form-control"
        />
      </div>
     
      <div className="gps-group-wrapper">
        <div className="form-group">
          <label htmlFor="postalCode">Código Postal:</label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={producerInfo.postalCode}
            onChange={(e) => handleAddressChange("postalCode", e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group"  style={{ marginLeft: "0.5rem" }}>
        <label htmlFor="city">Localidade:</label>
        <input
          type="text"
          id="city"
          name="city"
          value={producerInfo.city}
          onChange={(e) => handleAddressChange("city", e.target.value)}
          className="form-control"
        />
      </div>
      </div>
      <div className="gps-group-wrapper">
      <div className="form-group">
          <label htmlFor="country">Distrito:</label>
          <input
            type="text"
            id="district"
            name="district"
            value={producerInfo.distrito}
            onChange={(e) => handleAddressChange("district", e.target.value)}
            className="form-control"
          />
        </div>
       
        </div>
      <div className="gps-group-wrapper">
      <div className="form-group">
          <label htmlFor="country">Zona:</label>
          <input
            type="text"
            id="region"
            name="region"
            value={producerInfo.region}
            onChange={(e) => handleAddressChange("region", e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group" style={{ marginLeft: "0.5rem" }}>
          <label htmlFor="country">País:</label>
          <input
            type="text"
            id="country"
            name="country"
            value={producerInfo.country}
            onChange={(e) => handleAddressChange("country", e.target.value)}
            className="form-control"
          />
        </div>

       
        
      </div>
      


     
    </div>
  );
};

export default RegisterProducer;
