import React, { useState, useEffect, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { URL } from "../../config";
import Spinner from "../../assets/WeblandSpinner";
import { useDispatch } from "react-redux";
import { addProduct } from "../../redux/basketSlice";
import "./DesktopMarket.css";
import { AuthContext } from "../AuthContext";

const DesktopMarket = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  //const [dataRates, setRatesData] = useState([]);

  const { token } = useContext(AuthContext);

  const dispatch = useDispatch();
  const history = useHistory();

  /*const validProductIds = useMemo(() => {
    return new Set(dataRates.map((rate) => rate.product._id));
  }, [dataRates]);*/

  // Fetch products from API
  const fetchProducts = async () => {
    try {
      const response = await fetch(`${URL}/webland-api/product/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
        console.log("teste1");

      const data = await response.json();
      console.log("teste2");

      setProducts(data);
      console.log("teste3");

      setIsLoading(false);
      console.log("teste4");

    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        await fetchProducts(controller.signal); // Pass signal to the fetchProducts function
        setIsLoading(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error);
        }
      }
    };
  
    setIsLoading(true);
    fetchData();
  
    return () => {
      controller.abort(); // Clean up: abort any ongoing fetch when component unmounts
    };
  }, []);
  

  const handleProductClick = (product) => {
    // Redirecionar para a página de detalhes do produto
    history.push(`/produto?id=${product._id}`);
  };

  return isLoading ? (
    <div className="col-12 spinner-container-market">
      <Spinner />
    </div>
  ) : (
    <div className="col-12 market-desktop-container">
      <div className="col-12 market-product-selection">
        {products.map((product, index) => (
          <div
            key={index}
            className="market-product-card"
            onClick={() => handleProductClick(product)}
          >
            <div className="market-product-image">
              <img
                src={product.imageData}
                alt={product.type}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  marginBottom: "1rem",
                }}
                loading="lazy"
              />
            </div>
            <div className="market-product-title">{product.type}</div>
            <button
              className="market-add-button"
              onClick={(e) => {
                e.stopPropagation(); // Evita o clique no botão de adicionar redirecionar para a página de detalhes
                dispatch(addProduct(product));
              }}
            >
              Adicionar
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DesktopMarket;
