import React, { useContext, useEffect, useState } from "react";
import { useFilter } from "../../FilterContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Map from "./Map/Map";
import "./DashboardAdmin.css";
import { URL } from "../../config";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { MdLocationOn, MdCircle, MdMoreHoriz   } from "react-icons/md";
import { SiTarget } from "react-icons/si";
import Spinner from "../../assets/WeblandSpinner";
import { AuthContext } from "../AuthContext";
import { FaIndustry, FaTree, FaShoppingBasket   } from "react-icons/fa";

const DashboardAdmin = () => {
  const { filter } = useFilter();
  const [isLoading, setIsLoading] = useState(true);
  const [produtoresData, setProdutoresData] = useState([]);
  const [transformadoresData, setTransformadoresData] = useState([]);
  const [clientesData, setClientesData] = useState([]);
  const [filteredProdutoresData, setFilteredProdutoresData] = useState([]);
  const [filteredTransformadoresData, setFilteredTransformadoresData] = useState([]);
  const [filteredClientesData, setFilteredClientesData] = useState([]);
  const [currentDistrict, setCurrentDistrict] = useState("");


  console.log("filter", filter);
  const {token} = useContext(AuthContext)

  const history = useHistory();

  const [currentPageProdutor, setCurrentPageProdutor] = useState(1);
  const [currentPageTransformador, setCurrentPageTransformador] = useState(1);
  const [currentPageCliente, setCurrentPageCliente ] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    if (isLoading) {
      fetchProdutoresData();
      fetchTransformadoresData();
      fetchClientesData();
    } else applyFilters();
  }, [filter]);

  useEffect(() => {
    if (!isLoading) {
      applyFilters();
      setCurrentDistrict("")
    }
  }, [filter]);  // React to changes in filter context
  

  useEffect(() => {
   
    const filteredProdutores = produtoresData.filter((produtor) => String(produtor.district).includes(String(currentDistrict)));
    setFilteredProdutoresData(filteredProdutores);
    const filteredTransformadores = transformadoresData.filter((transformador) => String(transformador.district).includes(String(currentDistrict)));
    setFilteredTransformadoresData(filteredTransformadores);
    const filteredClientes = clientesData.filter((cliente) => String(cliente.district).includes(String(currentDistrict)));
    setFilteredClientesData(filteredClientes);
    console.log(clientesData);
  }, [currentDistrict]); 

  const fetchProdutoresData = () => {
    fetch(`${URL}/webland-api/producer/all`,  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const produtoresWithType = data?.map((produtor) => ({
          ...produtor,
          type: "produtor",
          quantity: produtor.stockBolota.map((item) => item.quantity),
        }));
        //console.log(produtoresWithType.map(({address}) => String(address).split(",")[0]))

        const sortedProdutores = produtoresWithType
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name));
        setProdutoresData(sortedProdutores);
        setFilteredProdutoresData(sortedProdutores);
      })
      .catch((error) => {
        console.error("Error fetching producer data:", error);
      });
  };

  const fetchTransformadoresData = () => {
    fetch(`${URL}/webland-api/transformer/all`,  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    } 
    )
      .then((response) => response.json())
      .then((data) => {
        const transformadoresWithType = data.map((transformador) => ({
          ...transformador,
          type: "transformador",
        }));

        //console.log(transformadoresWithType.map(({address}) => address))
        const sortedTransformadores = transformadoresWithType
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name));
        setTransformadoresData(sortedTransformadores);
        setFilteredTransformadoresData(sortedTransformadores);
       
      })
      .catch((error) => {
        console.error("Error fetching transformer data:", error);
      });
  };

  

  const fetchClientesData = () => {
    fetch(`${URL}/webland-api/client/all`, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        // First, map over data to create a promise for each client's orders
        const clientesWithOrdersPromise = data.map(cliente => 
          fetchClientOrders(cliente._id).then(orderCount => ({
            ...cliente,
            type: "cliente",
            sectionName: "Alimentar",
            orderCount: orderCount,
          }))
        );
  
        // Then, use Promise.all to wait for all those promises to resolve
        const clientesWithType = await Promise.all(clientesWithOrdersPromise);
  
        // Now you can sort the fully resolved array
        const sortedClientes = clientesWithType.sort((a, b) => a.name.localeCompare(b.name));
  
        // Finally, update your state with the resolved and sorted data
        setClientesData(sortedClientes);
        setFilteredClientesData(sortedClientes);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });
  };
  


  const fetchClientOrders = async (id) => {
    try {
      const orderResponse = await fetch(
        `${URL}/webland-api/order/orders/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const orders = await orderResponse.json();
      return (orders.length); // Update the orderCount for each client
    } catch (orderError) {
      console.error("Error fetching order data:", orderError);
    }
  };

  const createSpeciesButtons = (species) => {
    const allSpecies = ["Azinheira", "Carvalho", "Sobreiro"];
    const speciesMapping = { Azinheira: "A", Carvalho: "C", Sobreiro: "S" };
    const speciesColors = {
        Carvalho: "var(--secondary-color)",
        Azinheira: "var(--secondary-color)",
        Sobreiro: "var(--secondary-color)",
        default: "lightgray",
    };

    // Acessa o array de espécies diretamente se for um array
    const speciesArray = Array.isArray(species) ? species : species?.split(",").map((s) => s.trim());

    return allSpecies?.map((speciesKey) => {
        const isPresent = speciesArray?.some((sp) => {
            // Verifica se a espécie começa com "carvalho"
            if (sp.toLowerCase().startsWith("carvalho")) {
                return speciesKey === "Carvalho"; // Todos os "carvalho" são considerados como "Carvalho"
            }
            return sp === speciesKey; // Verifica igualdade com a chave da espécie
        });

        // Define a cor de fundo com base na presença da espécie
        const color = isPresent ? speciesColors[speciesKey] : speciesColors["default"];

        return (
            <span
                key={speciesKey}
                className="species-span"
                style={{ backgroundColor: color }}
            >
                {speciesMapping[speciesKey]} {/* Usa a letra correspondente */}
            </span>
        );
    });
};

  const createTransformButtons = (species) => {
    const allSpecies = ["Alimentar", "Química", "Biorefinarias"];
    const speciesMapping = { Alimentar: "A", Química: "Q", Biorefinarias: "B" };
    const speciesColors = {
        Alimentar: "var(--secondary-color)",
        Química: "var(--secondary-color)",
        Biorefinarias: "var(--secondary-color)",
      default: "lightgray",
    };
    const speciesArray = species?.split(",").map((s) => s.trim());

    return allSpecies.map((speciesKey) => {
      const letter = speciesMapping[speciesKey];
      const isPresent = speciesArray?.some(
        (sp) => sp.toLowerCase() === speciesKey.toLowerCase()
      );
      const color = isPresent
        ? speciesColors[speciesKey]
        : speciesColors["default"];

      return (
        <span
          key={speciesKey}
          className="species-span"
          style={{ backgroundColor: color }}
        >
          {letter}
        </span>
      );
    });
  };

  const createIndustryButtons = (industry) => {
    const allIndustries = [
      "alimentar",
      "restauracao",
      "padariapastelaria",
      "industriaquimica",
      "industriaalimentar",
      "insdustriabiomassa",
    ];
    const industryMapping = {
      "alimentar": "A", 
      "restauracao": "R", 
      "industriaquimica": "Q", 
      "industriaalimentar": "I", 
      "padariapastelaria": "P", 
      "insdustriabiomassa": "B"
    };
    const industryColors = {
      "alimentar": "var(--secondary-color)",
      "restauracao": "var(--secondary-color)",
      "industriaquimica": "var(--secondary-color)",
      "industriaalimentar": "var(--secondary-color)",
      "padariapastelaria": "var(--secondary-color)",
      "insdustriabiomassa": "var(--secondary-color)",
      "default": "lightgray",
    };
       
    const normalizeString = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z]/g, '').toLowerCase();
  
    
    const industryArray = industry?.map(normalizeString);
      //console.log(industryArray);
  
    return allIndustries.map(industryKey => {
      const normalizedIndustryKey = normalizeString(industryKey);
      const letter = industryMapping[industryKey];
      const isPresent = industryArray?.some(sp => sp === normalizedIndustryKey);
      const color = isPresent ? industryColors[industryKey] : industryColors["default"];
  
      return (
        <span key={industryKey} className="species-span" style={{ backgroundColor: color }}>
          {letter}
        </span>
      );
    });
  };
  

  const handleDistrictClick = (districtName) => {
    // You can do filtering or other actions based on the district name
    console.log("Selected District:", districtName);
    setCurrentDistrict(districtName);
    // Example of setting state based on the district click
   // setFilter({ ...filter, district: districtName });
    // Then call your filtering function or similar actions
  };
  
  

  const applyFilters = () => {
    if (!filter || Object.keys(filter).length === 0) {
      setFilteredProdutoresData(produtoresData);
      setFilteredTransformadoresData(transformadoresData);
      return;
    }

    const [, type, species, region] = filter.split("/");
  console.log(type, species, region)
    if (type === "produtores") {
      const filteredData = produtoresData?.filter((produtor) => {
        return (
          // (!species || produtor.bolotaSpecie.includes(species)) &&
          !region || produtor.region.toLowerCase() === region.toLowerCase()
        );
      });
      console.log("Filtered Data", filteredData);
      setFilteredProdutoresData(filteredData)
      if(species!==undefined) {

        const filteredDataBySpecies = filteredData?.filter((filter) => filter.bolotaSpecies && filter.bolotaSpecies.toLowerCase().includes(species) )
        //console.log("Espécie", filteredDataBySpecies);
        setFilteredProdutoresData(filteredDataBySpecies);
      }
      
      
    }

    if (type === "transformadores") {
      const filteredTransformadores = transformadoresData?.filter(
        (transformador) => {
          return (
            // (!species || produtor.bolotaSpecie.includes(species)) &&
            !region ||
            transformador.region.toLowerCase() === region.toLowerCase()
          );
        }
      );

      setFilteredTransformadoresData(filteredTransformadores);
    }

    if (type === "clientes") {
      const filteredClientesData = clientesData?.filter(
        (cliente) => {
          return (
            // (!species || produtor.bolotaSpecie.includes(species)) &&
            !region ||
            cliente.region.toLowerCase() === region.toLowerCase()
          );
        }
      );

      setFilteredClientesData(filteredClientesData);
    }
  };

  const startProdutoresIdx = (currentPageProdutor - 1) * itemsPerPage;
  const endProdutoresIdx = Math.min(
    startProdutoresIdx + itemsPerPage,
    produtoresData.length
  );

  const startTransformadoresIdx = (currentPageTransformador - 1) * itemsPerPage;
  const endTransformadoresIdx = Math.min(
    startTransformadoresIdx + itemsPerPage,
    transformadoresData.length
  );

  const startClientesIdx = (currentPageCliente - 1) * itemsPerPage;
  const endClientesIdx = Math.min(
    startClientesIdx + itemsPerPage,
    clientesData.length
  );


  
  

  return (
    <div className="col-12 row">
      <div className="col-xl-4 col-md-12 col-sm-12 map-container">
        {isLoading ? (
          <div className="col-12 spinner-container">
            <Spinner />
          </div>
        ) : (
          <Map
            locations={filteredProdutoresData.concat(
              filteredTransformadoresData,
              filteredClientesData
            )}
            onDistrictClick={handleDistrictClick}
          />
        )}
      </div>

      <div className="col-xl-8 col-md-12 col-sm-12 tables-container-wrapper">
        <div className="dashboard-tables-container">
          <div className="col-12 dashboard-card-table">
            <div className="col-12 dashboard-card-table-header">
              <div
                className="col-4 card-table-button"
                style={{ justifyContent: "left" }}
              >
                <button
                  className="previous-btn"
                  onClick={() =>
                    setCurrentPageProdutor((prevPage) =>
                      Math.max(prevPage - 1, 1)
                    )
                  }
                  disabled={currentPageProdutor === 1}
                >
                  <AiOutlineLeft size={12} />
                </button>
                <span>{currentPageProdutor}</span>
                <button
                  className="next-btn"
                  onClick={() =>
                    setCurrentPageProdutor((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(filteredProdutoresData.length / itemsPerPage)
                      )
                    )
                  }
                  disabled={endProdutoresIdx >= filteredProdutoresData.length}
                >
                  <AiOutlineRight size={12} />
                </button>
              </div>
              <div className="col-2 card-table-title">
                <FaTree
                  size={20}
                  style={{
                    position: "relative",
                    top: "0.15rem",
                    marginRight: "0.5rem",
                  }}
                />
                Produtores
              </div>
              <div className="col-4 card-table-button">
                <button
                  className="card-table-button-add"
                  onClick={() => history.push("/dashboard/produtores")}
                >
                  <MdMoreHoriz />
                </button>
              </div>
            </div>
            <div className="col-12 dashboard-card-table-body" >
              <table className="col-12 dashboard-user-table">
                <thead style={{ fontSize: "x-small" }}>
                  <tr>
                    <th>Nome</th>
                    {/* <th >NIF</th> */}
                    <th>Localidade</th>
                    {/* <th>Latitude</th>
                  <th>Longitude</th> */}
                    <th>Zona</th>
                    <th>Espécie</th>
                    <th>Stock</th>
                    <th ></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <div className="col-12 spinner-container">
                      <Spinner />
                    </div>
                  ) : (
                    filteredProdutoresData
                      .slice(startProdutoresIdx, endProdutoresIdx)
                      .map((produtor, index) => (
                        <tr key={index}>
                          <td>{produtor.name}</td>
                          {/* <td >{produtor.nif}</td> */}
                          <td>
                            {
                             produtor.city
                              
                            }
                          </td>
                          {/* <td>{produtor.lat}</td>
                    <td>{produtor.long}</td> */}
                          <td>{produtor.region}</td>
                          <td>
                          <div className="category-wrapper">{createSpeciesButtons(produtor.species)}</div>
                          </td>
                          <td style={{
                              background: "var(--primary-color",
                              color: "white",
                              borderRadius: "0.2rem",
                              textAlign: "center",
                            }}>
                            {!produtor.quantity
                              ? "0 "
                              : produtor.quantity.reduce(
                                  (accumulator, currentValue) => {
                                    return accumulator + currentValue;
                                  },
                                  0
                                )}
                            {" kg"}
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                history.push(
                                  `/dashboard/metrics/producer?id=${produtor._id}`
                                );
                              }}
                              className="btn-detalhes"
                            >
                              +Info
                            </button>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-12 dashboard-card-table">
            <div className="col-12 dashboard-card-table-header">
              <div
                className="col-4 card-table-button"
                style={{ justifyContent: "left" }}
              >
                <button
                  className="previous-btn"
                  onClick={() =>
                    setCurrentPageTransformador((prevPage) =>
                      Math.max(prevPage - 1, 1)
                    )
                  }
                  disabled={currentPageTransformador === 1}
                >
                  <AiOutlineLeft size={12} />
                </button>
                <span>{currentPageTransformador}</span>
                <button
                  className="next-btn"
                  onClick={() =>
                    setCurrentPageTransformador((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(
                          filteredTransformadoresData.length / itemsPerPage
                        )
                      )
                    )
                  }
                  disabled={
                    endTransformadoresIdx >= filteredTransformadoresData.length
                  }
                >
                  <AiOutlineRight size={12} />
                </button>
              </div>
              <div className="col-4 card-table-title">
                <FaIndustry
                  size={18}
                  style={{
                    position: "relative",
                    top: "0.1rem",
                    marginRight: "0.5rem",
                  }}
                />
                Transformadores
              </div>
              <div className="col-2 card-table-button">
                <button
                  className="card-table-button-add"
                  onClick={() => history.push("/dashboard/transformadores")}
                >
                   <MdMoreHoriz />
                </button>
              </div>
            </div>
            <div className="col-12 dashboard-card-table-body">
              <table className="col-12 dashboard-user-table">
                <thead style={{ fontSize: "x-small" }}>
                  <tr>
                    <th >Nome</th>
                    {/* <th >NIF</th> */}
                    <th >Localidade</th>
                    {/* <th>Latitude</th>
                  <th>Longitude</th> */}
                    <th >Zona</th>
                    <th >Tipo</th>
                    <th >Volume</th>
                    <th ></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <div className="col-12 spinner-container">
                      <Spinner />
                    </div>
                  ) : (
                    filteredTransformadoresData
                      .slice(startTransformadoresIdx, endTransformadoresIdx)
                      .map((transformador, index) => (
                        <tr key={index}>
                          <td >
                            {transformador.name}
                          </td>
                          {/* <td >{transformador.nif}</td> */}
                          <td >
                            {transformador.city}
                          </td>
                          {/* <td>{produtor.lat}</td>
                    <td>{produtor.long}</td> */}
                          <td >{transformador.region}</td>
                          <td >
                            <div className="category-wrapper"> {createTransformButtons(transformador.transformationType)}</div>
                          </td>
                          <td
                            
                            style={{
                              background: "var(--primary-color",
                              color: "white",
                              borderRadius: "0.2rem",
                              textAlign: "center",
                            }}
                          >
                            {!transformador.productQuantity
                              ? "0 "
                              : transformador.productQuantity}
                            kg
                          </td>
                          <td>
                          <button 
                          className="btn-detalhes"
                          onClick={() => history.push(`/dashboard/metrics/transformadores?id=${transformador._id}`)}
                        >
                            +Info
                        </button>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
             
            </div>
          </div>

          {/* Start of Table for 'Clientes' */}
          <div className="col-12 dashboard-card-table">
            <div className="col-12 dashboard-card-table-header">
            <div
                className="col-4 card-table-button"
                style={{ justifyContent: "left" }}
              >
                <button
                  className="previous-btn"
                  onClick={() =>
                    setCurrentPageCliente((prevPage) =>
                      Math.max(prevPage - 1, 1)
                    )
                  }
                  disabled={currentPageCliente === 1}
                >
                  <AiOutlineLeft size={12} />
                </button>
                <span>{currentPageCliente}</span>
                <button
                  className="next-btn"
                  onClick={() =>
                    setCurrentPageCliente((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(filteredClientesData.length / itemsPerPage)
                      )
                    )
                  }
                  disabled={endClientesIdx >= filteredClientesData.length}
                >
                  <AiOutlineRight size={12} />
                </button>
              </div>
              <div className="col-4 card-table-title">
                <FaShoppingBasket
                  size={18}
                  style={{
                    position: "relative",
                    top: "0.1rem",
                    marginRight: "0.5rem",
                  }}
                />
                Clientes
              </div>
              <div className="col-2 card-table-button">
                <button className="card-table-button-add"  onClick={()=> history.push("/dashboard/clientes")}> <MdMoreHoriz /></button>
              </div>
            </div>

            <div className="col-12 dashboard-card-table-body">
              <table className="col-12 dashboard-user-table"> 
       
                <thead style={{ fontSize: "x-small" }}>
                  <tr>
                    <th  >Nome</th>
                    {/* <th >NIF</th> */}
                    <th >Localidade</th>
                    {/* <th>Latitude</th>
                  <th>Longitude</th> */}
                    <th >Zona</th>
                    <th >Sector</th>
                    <th >Encomendas</th>
                    <th ></th>
                  </tr>
                </thead>
                <tbody>
                {isLoading ? (
                    <div className="col-12 spinner-container">
                      <Spinner />
                    </div>
                  ) : (
                   filteredClientesData
                      .slice(startClientesIdx, endClientesIdx)
                      .map((cliente, index) => (
                    <tr key={index}>
                      <td >{cliente.name}</td>
                      {/* <td >{cliente.nif}</td> */}
                      <td >{cliente.city }</td>
                      {/* <td>{produtor.lat}</td>
                    <td>{produtor.long}</td> */}
                      <td   >{cliente.region}</td>
                      <td ><div className="category-wrapper">{createIndustryButtons(cliente.category)}</div></td>
                      <td
                        
                        style={{
                          background: "var(--primary-color)",
                          color: "white",
                          borderRadius: "0.2rem",
                          textAlign: "center",
                          marginLeft: "-2px"
                        }}
                      >
                       {cliente.orderCount || 0}

                      </td>
                      <td>
                        <button 
                          className="btn-detalhes"
                          onClick={() => history.push(`/dashboard/metrics/client?id=${cliente._id}`)}
                      
                        >
                            +Info
                        </button>
                      </td>
                    </tr>
                  ))
                )}
                </tbody>
         
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
