import React, { useContext, useEffect, useState, useCallback } from "react";
import { useFilter } from "../../FilterContext";
import Map from "./Map/Map";
import "./Dashboard.css";
import { URL } from "../../config";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import {MdMoreHoriz} from "react-icons/md"
import Spinner from "../../assets/WeblandSpinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { AuthContext } from "../AuthContext";
import "./Produtores.css"
import DeleteConfirmationModal from "./DeleteModal";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import AprovalModal from'./AprovalModal'
import ProposalModal from'./ProposalModal'
import SuccessModal from "../Desktop/SuccessModal";   
import SuccessModal2 from "../Desktop/SuccessModal";   
import AprovalModalData from '../Admin/AprovalModal';  
import DataModalEdit from '../Producer/DataModal';


const Produtores = () => {
  const { filter } = useFilter();
  const [isLoading, setIsLoading] = useState(true);
  const [produtoresData, setProdutoresData] = useState([]);
  const [filteredProdutoresData, setFilteredProdutoresData] = useState([]);
  const [currentPageProdutor, setCurrentPageProdutor] = useState(1);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [userToDelete, setUserToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {token} = useContext(AuthContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const history = useHistory();

  const handleRowClick = (index, produtor) => {
    setSelectedRow(index);
    setSelectedRowData(produtor);
  };

  const handleMouseEnter = (index) => {
    // Handle mouse enter event, e.g., change row color on hover
    // Implement your own logic based on your requirements
  };

  const handleMouseLeave = () => {
    // Handle mouse leave event, e.g., reset row color on hover out
    // Implement your own logic based on your requirements
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      const requestData = {
        _id: userToDelete._id,
        email: userToDelete.email,
        role:"producer",
        
        // Adicione outros dados que deseja enviar aqui
      };
      console.log("o que recebo",userToDelete);
  
      fetch(`${URL}/webland-api/user/deleteUser`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      })
        .then((response) => {
          if (response.status === 200) {
            console.log("Usuário excluído com sucesso",requestData);
            window.location.reload();
          } else {
            console.error("Erro ao excluir o usuário",requestData);
          }
        })
        .catch((error) => {
          console.error("Erro na solicitação para excluir o usuário:", error);
        });
    }
    setShowDeleteModal(false);
    
  };
  const handleCancelDelete = () => {
    // Feche o modal sem realizar a exclusão.
    setShowDeleteModal(false);
  };

  const formatAcornType = (type) => {
    return type
      .replace(/_/g, ' ') // Substitui os underscores por espaços
      .replace(/^\w/, (c) => c.toUpperCase()); // Converte a primeira letra para maiúscula
  };
  const [showAprovalModal, setshowAprovalModal] = useState(false);
  const handleOpenModalAprovalModal = (item) => {
    setSelectedItem(item);
    setshowAprovalModal(true);
    console.log("teste price",item.price)
  };

  const handleConfirmAprovalModal = async (stockBolota) => {
    console.log("price", stockBolota);
    try {
      // Substitua `priceId` pelo ID do preço que você deseja atualizar
      const priceId = stockBolota._id; // Altere isso para a maneira como você está armazenando o ID do preço
      const response = await fetch(`${URL}/webland-api/price/concluido/${priceId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Inclua o token, se necessário
        },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao atualizar o preço');
      }
  
      const updatedPrice = await response.json();
      console.log("Preço aceito!", updatedPrice);
      // Aqui você pode adicionar qualquer lógica adicional que deseje executar após a atualização
     
     

    } catch (error) {
      console.error("Erro:", error.message);
    } finally {
     setshowAprovalModal(false);
     setShowSuccessModal2(true); 
    }
  };
  

  const handleCancelAprovalModal = () => {
    // Ação ao cancelar
    setshowAprovalModal(false);
  };
  const [showProposalModal, setShowProposalModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false); 
  const [showSuccessModal2, setShowSuccessModal2] = useState(false); 
  const [newPrice, setNewPrice] = useState(''); // Estado para armazenar o novo preço
  const [selectedItem, setSelectedItem] = useState(null); // Estado para armazenar o item selecionado
  const [showAprovalModalData, setshowAprovalModalData] = useState(false);
  const [showDeclineModalData, setshowDeclineModalData] = useState(false);
  const [newdeliveryDate, setNewDeliveryDate] = useState(""); 

  const handleOpenProposalModal = (item) => {
    setSelectedItem(item);
    setShowProposalModal(true);
  };

  const handleConfirmProposalModal = async () => {
    if (!selectedItem || !newPrice) return; // Verifique se existe um item selecionado e um preço

    try {
        const priceId = selectedItem._id; // Obtenha o ID do preço
        const response = await fetch(`${URL}/webland-api/price/update/${priceId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ price: newPrice , status:"pendente", type:"admin" }), // Envie o novo preço
        });

        if (!response.ok) {
            throw new Error('Erro ao atualizar o preço');
        }

        const updatedPrice = await response.json();
        console.log("Preço atualizado com sucesso!", updatedPrice);
        // Atualize os dados conforme necessário ou recarregue a página

        // Opcional: Recarregar a página ou atualizar os dados
       

    } catch (error) {
        console.error("Erro:", error.message);
    } finally {
        setShowProposalModal(false); // Fecha o modal
        setShowSuccessModal(true); 
        setNewPrice(''); // Limpa o campo de entrada
    }
};
const handleCloseSuccessModal = () => {
  setShowSuccessModal(false);  // Fecha o SuccessModal
  window.location.reload();  
};
const handleCloseSuccessModal2 = () => {
  setShowSuccessModal2(false);  // Fecha o SuccessModal
  window.location.reload();  
};


  const handleCancelProposalModal = () => {
    setShowProposalModal(false);
    setNewPrice(''); // Limpa o campo de entrada ao cancelar
  };
  const handleCancelAprovalModalData = () => {
   
    setshowAprovalModalData(false);
  };
  const handleOpenAprovalModalData = () => {

    setshowAprovalModalData(true);
  };

  const handleConfirmAprovalModalData = async (item) => {
    console.log("dentro modal", item);
    try {
      // Faz a requisição PUT para atualizar o status da entrega
      const response = await fetch(`${URL}/webland-api/delivery_date/updateconcluido/${item}`, { // Supondo que item tenha a propriedade deliveryId
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`     // Adicione seu token de autorização se necessário
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log(result); // Log do resultado para debug

      // Aqui você pode atualizar o estado ou fazer o que for necessário após a atualização
      setshowAprovalModalData(false); // Fecha o modal após a confirmação
        // Recarregar a página para refletir as mudanças
    window.location.reload();
    } catch (error) {
      console.error('Erro ao atualizar os dados da entrega:', error);
      // Trate o erro conforme necessário, talvez mostrando uma mensagem ao usuário
    }
  };


  const handleConfirmDeclineModalData = async (item, newdate) => {
    console.log("dentro modal declínio", item);
  try {
    // Define os valores que deseja atualizar
    const updatedData = {
      status: "pendente",  // Exemplo de status ao rejeitar
      type: "admin",     // Tipo pode ser "declined" ou conforme a lógica da sua aplicação
      delivery_date: newdate // Mantém a data de entrega existente
    };

    // Faz a requisição PUT para atualizar os dados da entrega
    const response = await fetch(`${URL}/webland-api/delivery_date/update/${item}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}` // Adicione seu token de autorização se necessário
      },
      body: JSON.stringify(updatedData)  // Envia os dados no corpo da requisição
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const result = await response.json();
    console.log("Resultado da rejeição:", result); // Log do resultado para debug

    // Aqui você pode atualizar o estado ou fazer o que for necessário após a atualização
    setshowDeclineModalData(false); // Fecha o modal após a confirmação
    window.location.reload(); // Recarregar a página para refletir as mudanças
  } catch (error) {
    console.error('Erro ao atualizar os dados da entrega:', error);
    // Trate o erro conforme necessário, talvez mostrando uma mensagem ao usuário
  }
};
  
const handleCloseDeclineModalData = () => {
   
  setshowDeclineModalData(false);
};

  const handleOpenDeclineModalData = () => {

    setshowDeclineModalData(true);
  };

  const SelectedRowInfo = () => {
    const {
      _id,
      address,
      bolotaSpecies,
      createdAt,
      email,
      lat,
      long,
      name,
      nif,
      phoneNumber,
      quantity,
      region,
      species,
      stockBolota,
      type,
      city,
      street,
      updatedAt,
      warehouseHistory,
    } = selectedRowData;
    console.log("stockBolota admin", stockBolota);
   
    return (
      <div className="total">
        <div className="data-produtores-container" style={{ display: 'flex', alignItems: 'flex-start' }}>
        <div className="col-4" >
          {/* Informações do Produtor */}
          <div className="producer-informacao">
            <h4>Informações</h4>
            <p>Nome do Produtor: {name? name: " - "}</p>
            <p>Email: {email ? email:" - "}</p>
            <p>NIF:  {nif}</p>
            <p>Contacto: {phoneNumber? phoneNumber: " - "}</p>
            <p>Localidade:  {city ? city: " - "}</p>
          </div>
        </div>

        
         <div className="col-8 producer-informacao2" >
         {stockBolota && stockBolota.length > 0 ? (
         <table class="custom-table" style={{width:"100%"}}>
  <thead>
    <tr>
      <th>Espécie</th>
      <th>Quantidade</th>
      <th>Data de Colheita</th>
      <th>Preço</th>
      <th>Data de Entrega</th>
      <th>Estado</th>
    </tr>
  </thead>
  <tbody>
  {stockBolota && stockBolota.length > 0 ? (
      stockBolota.map((item, index) => (
        <tr key={index}>
          <td>{formatAcornType(item.acornType)}</td>
          <td>{item.quantity} Kg</td>
          <td>
          {new Date(item.harvest_date).toLocaleDateString('pt-PT', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })}
           </td>
          <td>{item.price}€</td>
          <td>{item?.deliveryData?.delivery_date}
          {item?.deliveryData?.type === "produtor" && item?.deliveryData?.status === "pendente" && (
    <div>
     <FaCheck
              style={{ width: "15%", height: "15%", cursor: "pointer" }}
              onClick={handleOpenAprovalModalData}
            />
  {/* Modal */}
  {showAprovalModalData && (
        <AprovalModalData 
          message={`Aceitar a data ${item?.deliveryData?.delivery_date} proposta pelo Produtor ?`} 
          onConfirm={() => handleConfirmAprovalModalData(item?.deliveryData?._id)} // Passando a função corretamente
          onCancel={handleCancelAprovalModalData} 
        />
      )}
    
              <RxCross2
              style={{ width: "15%", height: "30%", marginLeft: "15%", color: "#dc3300", cursor: "pointer" }}
              onClick={() => handleOpenDeclineModalData(item)}
            />
             {showDeclineModalData && (
        <DataModalEdit 
        message="Insira nova data de Entrega:"
        delivery_date={
          <input
            type="date"
            value={newdeliveryDate}
            onChange={(e) => setNewDeliveryDate(e.target.value)} // Atualiza o estado com a data
          />
        }
          onConfirm={() => handleConfirmDeclineModalData(item?.deliveryData?._id,newdeliveryDate)} // Passando a função corretamente
          onCancel={handleCloseDeclineModalData} 
        />
      )}
    </div>
  )}



          </td>
          <td>   {item.type === "produtor" && item.status === "pendente" ? (
           <div><FaCheck style={{width:"25%", height:"25%", cursor: "pointer" }} onClick={() => handleOpenModalAprovalModal(item)}/>    {showAprovalModal && selectedItem && (
            <AprovalModal 
            message={`Aceitar o preço de ${selectedItem.price}€/Kg proposto pelo Produtor?`} 
            onConfirm={() => handleConfirmAprovalModal(item)} // Passando a função corretamente
            onCancel={handleCancelAprovalModal} 
            />
          )} <RxCross2  style={{width:"25%", height:"25%", color:"#dc3300", cursor: "pointer" }} onClick={() => handleOpenProposalModal(item)} />
            {showProposalModal && (
        <ProposalModal 
          message="Insira o novo preço:"
          price={
            <input 
              type="number" 
              value={newPrice} 
              onChange={(e) => setNewPrice(e.target.value)} 
              placeholder="Novo preço" 
            />
          }
          onConfirm={handleConfirmProposalModal} 
          onCancel={handleCancelProposalModal} 
        />
      )}
         
           
           
           </div>
          ) : item.type === "admin" && item.status === "pendente" ? (
            "Aguarda validação do Produtor"
          ) : item.status === "concluido" ? (
            " Preço Concluído"
          ) : (
            "Outro Status"
          )}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="2">-</td>
      </tr>
    )}
  </tbody>
</table>
    ) : null} 
</div>
<SuccessModal2
        isOpen={showSuccessModal}
        onClose={handleCloseSuccessModal2}         // Fecha o SuccessModal
        message="A proposta foi aprovada com sucesso!"
        title="Sucesso"
      />
<SuccessModal
        isOpen={showSuccessModal}
        onClose={handleCloseSuccessModal}         // Fecha o SuccessModal
        message="A proposta foi enviada com sucesso!"
        title="Sucesso"
      />
        </div>
     {/*   <div className="col-12 selected-row-data mt-2 mb-3">
       
      <div className="col-3">
        <strong>Bolota espécies:</strong>
        <div>
          {species && species.length > 0 ? (
            species.map((specie, index) => (
              <span key={index}>
                {formatAcornType(specie)}
                {index < species.length - 1 && ", "}
              </span>
            ))
          ) : (
            " - "
          )}
        </div>
      </div>
        <div className="col-3"><strong>Latitude:</strong><div> {lat ? lat : " - "}</div></div>
        <div className="col-3"><strong>Longitude:</strong> <div>{long ? long : " - "}</div></div>
        </div>
        <div></div>
        <div className="col-12 selected-row-data"></div>
       
       <div className="col-3">
        <strong>Stock Bolota:</strong>
        <div>
          {stockBolota && stockBolota?.length > 0 ? (
            stockBolota.map((item, index) => (
              <div key={index}>
                <span>{formatAcornType(item.acornType)}:</span> <span>{item.quantity} Kg</span>
              </div>
            ))
          ) : (
            " - "
          )}
        </div>
      </div>
        */}
     
     <div className="buttons-container col-12 mt-2" >
      <div>
          <button className="erase-btn" style={{color:"white"}} onClick={() => handleDeleteClick(selectedRowData)} disabled={ selectedRowData._id  ? false : true}>Apagar</button>
          <button className="edit-btn" onClick={() => history.push(`/editusers?id=${selectedRowData._id}&role=producer`)}  disabled={ selectedRowData._id   ? false : true} >Editar</button>
          <button 
            className="metric-btn"
            disabled={ selectedRowData._id  ? false : true}
            onClick={() => history.push(`/dashboard/metrics/producer?id=${selectedRowData._id}`)}>
              Métricas
          </button>
          </div>
        </div>
        <DeleteConfirmationModal
          show={showDeleteModal}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete} />
      
      </div>
    );
  };

  // Custom hook for debouncing values
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

  

  const [nameFilter, setNameFilter] = useState("");
  const [localFilter, setLocalFilter] = useState("");
  const [zonaFilter, setZonaFilter] = useState("");
  const [tipoFilter, setTipoFilter] = useState("");


  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  const handleLocalFilterChange = (event) => {
    setLocalFilter(event.target.value);
  };

  const handleZonaFilterChange = (event) => {
    setZonaFilter(event.target.value);
  };

  const handleTipoFilterChange = (event) => {
    setTipoFilter(event.target.value);
  };

  const handleCleanFilters = () => {
    setNameFilter("");
    setLocalFilter("");
    setZonaFilter("");
    setTipoFilter("");
    setSelectedRowData("");
    setSelectedRow("")
  };

  const handleApplyFilters = () => {
    applyFilters();
  };

  const itemsPerPage = 6;

  const debouncedNameFilter = useDebounce(nameFilter, 500); // 500ms delay
const debouncedLocalFilter = useDebounce(localFilter, 500);
const debouncedZonaFilter = useDebounce(zonaFilter, 500);
const debouncedTipoFilter = useDebounce(tipoFilter, 500);

useEffect(() => {
  if (isLoading) {
    fetchProdutoresData();
  } 
}, [selectedRowData, selectedRowData]);


const fetchProdutoresData = async () => {
  try {
    // 1. Buscar dados dos produtores
    const produtoresResponse = await fetch(`${URL}/webland-api/producer/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const produtoresData = await produtoresResponse.json();

    // 2. Buscar todos os preços de uma vez
    const priceResponse = await fetch(`${URL}/webland-api/price/prices/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const pricesData = await priceResponse.json();

    // 3. Associar preços, status e tipo ao `stockBolota` de cada produtor
    const allUpdatedProdutores = produtoresData.map((produtor) => {
      const updatedStockBolota = produtor.stockBolota.map((item) => {
        const matchedPrice = pricesData.find(
          (price) => price.name_product.toLowerCase() === item.acornType.toLowerCase()
        );
        return {
          ...item,
          _id: matchedPrice ? matchedPrice._id : "N/A",
          price: matchedPrice ? matchedPrice.price : "N/A",
          status: matchedPrice ? matchedPrice.status : "N/A",
          type: matchedPrice ? matchedPrice.type : "N/A",
          harvest_date: item.harvest_date ? item.harvest_date : "N/A",
        };
      });

      return {
        ...produtor,
        type: "produtor",
        stockBolota: updatedStockBolota,
      };
    });

    // 4. Ordenar os produtores por nome
    const sortedProdutores = allUpdatedProdutores.slice().sort((a, b) => a.name.localeCompare(b.name));

    // Atualiza o estado com os produtores ordenados
    setProdutoresData(sortedProdutores);
    setFilteredProdutoresData(sortedProdutores);

    // 5. Agora, busque as datas de entrega para cada item
    const deliveryDates = await fetchDeliveryDates(allUpdatedProdutores);

    // Associa as datas de entrega ao `stockBolota`
    const updatedProdutoresWithDeliveryDates = sortedProdutores.map((produtor) => {
      const updatedStockBolotaWithDelivery = produtor.stockBolota.map((item) => {
        // Encontre a data de entrega correspondente pelo `delivery_date` (que é o ID)
        const matchedDelivery = deliveryDates[item.delivery_date];

        // Retorne o item com os dados de entrega adicionados
        return {
          ...item,
          deliveryData: matchedDelivery || {}, // Adiciona os dados de entrega ou objeto vazio se não for encontrado
        };
      });

      return {
        ...produtor,
        stockBolota: updatedStockBolotaWithDelivery, // Atualiza o stockBolota com as datas de entrega
      };
    });

    // Atualiza o estado com os produtores com as datas de entrega
    setProdutoresData(updatedProdutoresWithDeliveryDates);
    setFilteredProdutoresData(updatedProdutoresWithDeliveryDates);

    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching producer data:", error);
    setIsLoading(false); // Certifique-se de atualizar o estado de loading em caso de erro
  }
};


const fetchDeliveryDates = async (producers) => {
  console.log("aqui", producers);

  // Vamos acumular todas as promessas de fetch em um único array
  const fetchPromises = [];

  // Itera sobre cada produtor
  producers.forEach((producer) => {
    // Verifica se o produtor tem um stockBolota
    if (producer?.stockBolota && Array.isArray(producer?.stockBolota)) {
      // Itera sobre cada item em stockBolota
      producer?.stockBolota?.forEach((item) => {
        if (item?.delivery_date) { // Verifica se existe delivery_date
          fetchPromises.push(
            (async () => {
              try {
                const deliveryDateId = item?.delivery_date; // Obtém o ID do delivery_date
                const response = await fetch(`${URL}/webland-api/delivery_date/getDeliveryData/${deliveryDateId}`, {
                  headers: {
                    Authorization: `Bearer ${token}`, // Adicione o token, se necessário
                  },
                });

                if (!response.ok) {
                  throw new Error(`Erro ao buscar data de entrega para ID ${deliveryDateId}: ${response.statusText}`);
                }

                const data = await response.json();
                console.log("data", deliveryDateId, data);

                return { id: deliveryDateId, data }; // Retorna o ID e os dados da entrega
              } catch (error) {
                console.error(`Erro ao buscar data de entrega: ${error.message}`);
                return { }; // Retorna null em caso de erro
              }
            })()
          );
        }
      });
    }
  });

  // Aguarda todas as promessas serem resolvidas
  const deliveryDataArray = await Promise.all(fetchPromises);

  // Organiza os dados de entrega em um objeto
  const deliveryDataMap = deliveryDataArray.reduce((acc, deliveryData) => {
    acc[deliveryData.id] = deliveryData.data;
    return acc;
  }, {});

  return deliveryDataMap;
};

  const createSpeciesButtons = (species) => {
    const allSpecies = ["Azinheira", "Carvalho", "Sobreiro"];
    const speciesMapping = { Azinheira: "A", Carvalho: "C", Sobreiro: "S" };
    const speciesColors = {
        Carvalho: "var(--secondary-color)",
        Azinheira: "var(--secondary-color)",
        Sobreiro: "var(--secondary-color)",
        default: "lightgray",
    };

    // Acessa o array de espécies diretamente se for um array
    const speciesArray = Array.isArray(species) ? species : species?.split(",").map((s) => s.trim());

    return allSpecies?.map((speciesKey) => {
        const isPresent = speciesArray?.some((sp) => {
            // Verifica se a espécie começa com "carvalho"
            if (sp.toLowerCase().startsWith("carvalho")) {
                return speciesKey === "Carvalho"; // Todos os "carvalho" são considerados como "Carvalho"
            }
            return sp === speciesKey; // Verifica igualdade com a chave da espécie
        });

        // Define a cor de fundo com base na presença da espécie
        const color = isPresent ? speciesColors[speciesKey] : speciesColors["default"];

        return (
            <span
                key={speciesKey}
                className="species-span"
                style={{ backgroundColor: color }}
            >
                {speciesMapping[speciesKey]} {/* Usa a letra correspondente */}
            </span>
        );
    });
};


  useEffect(() => {
   
    console.log("filtro",typeof(filter) === typeof("String"));
    if (typeof(filter) === typeof("String")) {
    console.log("filtro",filter.split("/"));
    setZonaFilter(filter.split("/")[3]);
    setTipoFilter(filter.split("/")[2]);
    } else applyFilters();
  }, [filter]);

  useEffect(() => {
    applyFilters();
  }, [debouncedNameFilter, debouncedLocalFilter, debouncedZonaFilter, debouncedTipoFilter]);
  
  // Make sure applyFilters is wrapped with useCallback and has correct dependencies
  const applyFilters = useCallback(() => {
    let filteredData = produtoresData;
  
    // Apply filters using debounced filter values
    if (debouncedNameFilter) {
      filteredData = filteredData.filter(item =>
        item.name?.toLowerCase().includes(debouncedNameFilter.toLowerCase())
      );
    }
  
    if (debouncedLocalFilter) {
      filteredData = filteredData.filter(item =>
        item.city?.toLowerCase().includes(debouncedLocalFilter.toLowerCase())
      );
    }
  
    if (debouncedZonaFilter) {
      filteredData = filteredData.filter(item =>
        item.region?.toLowerCase().includes(debouncedZonaFilter.toLowerCase())
      );
    }
  
    if (debouncedTipoFilter) {
      filteredData = filteredData.filter(item => {
        // Garante que species seja sempre um array
        const speciesArray = Array.isArray(item.species)
          ? item.species
          : typeof item.species === "string" ? item.species.split(",") : [];
    
        return speciesArray.some(specie => {
          const formattedSpecie = specie?.toLowerCase().trim();
    
          // Se o filtro for "carvalho", considera os três tipos de Carvalho
          if (debouncedTipoFilter.toLowerCase() === "carvalho") {
            return ["carvalho_negral", "carvalho_alvarinho", "carvalho_portugues"].includes(formattedSpecie);
          }
    
          // Caso contrário, filtra normalmente
          return formattedSpecie === debouncedTipoFilter.toLowerCase().trim();
        });
      });
    }
    
  
    setFilteredProdutoresData(filteredData);
  }, [debouncedNameFilter, debouncedLocalFilter, debouncedZonaFilter, debouncedTipoFilter, produtoresData]);
  
  

  const startProdutoresIdx = (currentPageProdutor - 1) * itemsPerPage;
  const endProdutoresIdx = Math.min(
    startProdutoresIdx + itemsPerPage,
    produtoresData.length
  );

  return (
    <div className="col-12 row">
      <div className="col-xl-4 col-md-12 col-sm-12 map-container">
        {isLoading ? (
          <div className="col-12 spinner-container">
            <Spinner />
          </div>
        ) : (
          <Map locations={filteredProdutoresData} selectedMarker = {selectedRowData}/>
        )}
      </div>

      <div className="col-xl-8 col-md-12 col-sm-12 tables-container-wrapper">
        <div className="tables-container">
         
          <div className="card-table" style={{background:"white"}}>
            <div
              className="card-table-header"
              style={{ background: "white" }}
            >
              <div
                className="col-1 card-table-button"
                style={{ color: "var(--primary-color)" }}
              ></div>
              <div className="col-10 card-table-title"></div>
              <div className="col-1 card-table-button">
                {/* <button onClick={handleApplyFilters}>Apply</button> */}
              </div>
            </div>
           
              <div className="card-table-body">
                <div className="col-11 card-table-body-filter">
                  <div
                    className="col-xl-1 col-md-2 col-sm-2"
                    style={{ textAlign: "left" }}
                  >
                    Filtros
                  </div>
                  <div className="col-xl-2 col-md-2 col-sm-6">
                    <input
                      type="text"
                      value={nameFilter}
                      onChange={handleNameFilterChange}
                      placeholder="Nome"
                      style={{ padding: "0.15rem 0.5rem" }}
                    />
                  </div>
                  <div className="col-xl-2 col-md-2 col-sm-6">
                    <input
                      type="text"
                      value={localFilter}
                      onChange={handleLocalFilterChange}
                      placeholder="Localidade"
                      style={{
                        padding: "0.15rem 0.5rem",
                        marginLeft: "0.5rem",
                      }}
                    />
                  </div>
                  <div
                    className="col-xl-1 col-md-2 col-sm-6"
                    style={{ width: "5%" }}
                  >
                    <select
                      value={zonaFilter}
                      onChange={handleZonaFilterChange}
                      style={{ marginLeft: "0.5rem" }}
                    >
                      <option value="">Zona</option>
                      <option value="Norte">Norte</option>
                      <option value="Centro">Centro</option>
                      <option value="Sul">Sul</option>
                    </select>
                  </div>
                  <div className="col-xl-1 col-md-2 col-sm-6">
                    <select
                      value={tipoFilter}
                      onChange={handleTipoFilterChange}
                    >
                      <option value="">Espécie</option>
                      <option value="Azinheira">Azinheira</option>
                      <option value="Carvalho">Carvalho</option>
                      <option value="Sobreiro">Sobreiro</option>
                    </select>
                  </div>
                  <div className="col-xl-1 col-md-2 col-sm-6">
                    <button
                      className="filter-clean-button"
                      onClick={handleCleanFilters}
                    >
                      Limpar
                    </button>
                  </div>
                </div>
              </div>
          
          </div>
          <div className="card-table" style={{background:"white"}}>
            <div className="card-table-header">
              <div
                className="col-2 card-table-button"
                style={{ justifyContent: "left" }}
              >
                <button
                  className="previous-btn"
                  onClick={() =>
                    setCurrentPageProdutor((prevPage) =>
                      Math.max(prevPage - 1, 1)
                    )
                  }
                  disabled={currentPageProdutor === 1}
                >
                  <AiOutlineLeft size={12} />
                </button>
                <span>{currentPageProdutor}</span>
                <button
                  className="next-btn"
                  onClick={() =>
                    setCurrentPageProdutor((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(filteredProdutoresData.length / itemsPerPage)
                      )
                    )
                  }
                  disabled={endProdutoresIdx >= filteredProdutoresData.length}
                >
                  <AiOutlineRight size={12} />
                </button>
              </div>
              <div className="col-8 card-table-title">Produtores</div>
              <div className="col-2 card-table-button">
                {/* <button className="card-table-button-add"> <MdMoreHoriz /></button> */}
              </div>
            </div>
            <div className="dashboard-card-table-body" >
              <table className="dashboard-user-table" style={{borderCollapse:"collapse", width:"99%", marginBottom:"0.5rem"}}>
                <thead style={{ fontSize: "x-small" }}> 
    <tr >
      <th >Nome</th>
      <th >Localidade</th>
      <th >Zona</th>
      <th >Espécie</th>
      <th >Stock</th>
    </tr>
  </thead>
  <tbody>
    {isLoading ? (
      <div className="col-12 spinner-container">
        <Spinner />
      </div>
    ) : (
      filteredProdutoresData
        .slice(startProdutoresIdx, endProdutoresIdx)
        .map((produtor, index) => (
          <tr key={index}
            className={`${selectedRow === index ? 'tr-produtores  selected-row table-row-height-45' : 'tr-produtores table-row-height-45'}`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave()}
            onClick={() => handleRowClick(index, produtor)}
            style={{ borderBottom:"2px solid white"}}
          >
            <td className="" style={{ cursor: "pointer" }}>{produtor.name}</td>
            <td className="table-cell"  style={{ cursor: "pointer" }}>{produtor.city}</td>
            <td className="table-cell"  style={{ cursor: "pointer" }}>{produtor.region}</td>
            <td className="producer-category-wrapper"  style={{ cursor: "pointer" }}>{createSpeciesButtons(produtor.species)}</td>
            <td className="table-cell stock-cell" style={{marginBottom:"0.5rem", cursor: "pointer" }}>
              {produtor.quantity
                ? produtor.quantity.reduce((acc, val) => acc + val, 0) + " kg"
                : "0 kg"}
            </td>
          </tr>
        ))
    )}
  </tbody>
</table>

            </div>
          </div>
        </div>
        {" "}
        {
          selectedRow!== null && (

          <div className="card-table mt-1" style={{background:"white"}}>
            <div
              className="card-table-header"
              style={{ background: "white" }}
            >
              <div
                className="col-1 card-table-button"
                style={{ color: "var(--primary-color)" }}
              ></div>
              <div className="col-10 card-table-title"></div>
              <div className="col-1 card-table-button">
                {/* <button onClick={handleApplyFilters}>Apply</button> */}
              </div>
            </div>
           
              <div className="card-table-body" style={{margin:0, padding:0}}>
              
                  {SelectedRowInfo()}
                 
                 
             
              </div>
          
          </div>
            )
}
      </div>
    </div>
  );
};

export default Produtores;
