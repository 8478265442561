import React from "react";
import { MdEmail } from "react-icons/md";


const ModalForgotPassword = ({ onClose, onSubmit, email, setEmail }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{height:"14rem", width:"16rem"}}>
        
        <div className="modal-logo">
          <MdEmail size={40}/> {/* Icon indicating email */}
        </div>
        <p >
          Um email será enviado para:
        </p>              
        <p>
        <b>
        <input
          type="email"
          placeholder="Digite seu email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="email-input"
          style={{ width: "90%", padding: "0.5rem" }}
        /></b>
        </p> 
        
        <div className="modal-buttons-wrapper mb-3">
          <button className="btn-ok" onClick={onSubmit}>Enviar</button>
          <button className="btn-no" onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default ModalForgotPassword;
