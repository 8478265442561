import React, { useContext, useEffect, useState } from "react";
import { URL } from "../../config";
import Spinner from "../../assets/WeblandSpinner";
import { useHistory } from "react-router-dom";
import { AiFillEdit , FaPencilAlt} from "react-icons/ai";
import "../../global.css";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { AiFillHome, AiOutlineMinusSquare } from "react-icons/ai";
import { MdOutlineAddBox, MdOutlineMinusBox, MdOutlineWarehouse, MdOutlineReadMore } from "react-icons/md";
import { RiContactsLine } from "react-icons/ri";
import { AuthContext } from "../AuthContext";
import AddBolotaModal from "../Mobile/AddBolotaModal";
import EditBolotaModal from "../Mobile/EditBolotaModal";



ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale
);

function ProducerLayout() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [produtorData, setProdutorData] = useState({});
  const [producerId, setProducerId] = useState(null);
  const [, dashboard, , type] = history.location.pathname.split("/");
  const { token } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProduct, setEditedProduct] = useState({});
  const [idProducer, setIdProducer] = useState(null);
  


  const fetchProducerData = (id) => {
    fetch(`${URL}/webland-api/producer/${localStorage.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const produtorWithType = data.map((produtor) => ({
          ...produtor,
          type: "produtor",
          quantity: produtor?.stockBolota?.map((item) => item.quantity),
        }));
        setProdutorData(produtorWithType[0]);
       // setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching producer data:", error);
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const idFromURL = localStorage.id;
    console.log("localStorage",localStorage)
    const emailFromURL = urlParams.get("email");

    const fetchIdByEmail = async (email) => {
      try {
        const response = await fetch(`${URL}/webland-api/producer/idByEmail/${email}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setIdProducer(data.id);
        } else {
          console.error("Falha ao buscar os dados do usuário");
        }
      } catch (error) {
        console.error("Erro ao buscar os dados do usuário:", error);
      }
    };

    if (emailFromURL) {
      fetchIdByEmail(emailFromURL);
    }
    if (idFromURL) {
      setProducerId(idFromURL);
      fetchProducerData(idFromURL);
    }
  }, []);

  useEffect(() => {
    if (idProducer) {
      fetchProducerData(idProducer);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const idFromURL = urlParams.get("id");

    if (idFromURL) {
      setProducerId(idFromURL);
      fetchProducerData(idFromURL);
    }
  }, [idProducer]);

  const handleModalClose = () => {
    setShowModal(false);
    setIsEditing(false);
    fetchProducerData(producerId || idProducer);
  };

  const { stockBolota, warehouseHistory } = produtorData;
  
  return isLoading ? (
    <Spinner />
  ) : (
    <div className="producer-metrics-container">
  
      <div className="row">
        <div className="col-md-6">
          <ProducerInfo produtorData={produtorData} />
        </div>
        {/*
        <div className="col-md-3">
          <WarehouseStock
            stockBolota={stockBolota}
            showModal={showModal}
            setShowModal={setShowModal}
            setIsEditing={setIsEditing}
            editedProduct={editedProduct}
            setEditedProduct={setEditedProduct}
            isEditing={isEditing}
            onModalClose={handleModalClose} 
          />
        </div>
        <div className="col-md-5">
          <WarehouseHistory history={warehouseHistory} />
  </div>*/}
  
      </div>
    </div>
  );
}

const Breadcrumb = ({ producerId, dashboard }) => {
  const history = useHistory();

  const navigateTo = (path) => {
    history.push(path);
  };


  return (
    <div className="breadcrumb-wrapper">
      <span className="breadcrumb-item" onClick={() => navigateTo('/dashboard')}>
        {dashboard ? dashboard.toUpperCase() : 'DASHBOARD'}
      </span>
      <span> &gt; </span>
      <span className="breadcrumb-item" onClick={() => navigateTo('/dashboard/produtores')}>
        Produtores
      </span>
      <span> &gt; </span>
      <span className="breadcrumb-item">
        {producerId}
      </span>
    </div>
  );
};
const formatAcornType = (type) => {
  return type
    .replace(/_/g, ' ') // Substitui os underscores por espaços
    .replace(/^\w/, (c) => c.toUpperCase()); // Converte a primeira letra para maiúscula
};


const ProducerInfo = ({ produtorData }) => {
    const history = useHistory();

  
  const { nif, email, phoneNumber, address, region, lat, long, createdAt, city } = produtorData;
  const navigateToEdit = (path) => {
    history.push(path);
  };
  const [isHovered, setIsHovered] = useState(false);


  return (
    <div className="card producer-info-card">
      <div className="card-body">
        <h5 className="card-title"> Informação do Produtor</h5>
        <div className="row">
        <div className="col-md-9">
        <p><strong>Nome:</strong> {produtorData.name}</p>
        <p><strong>NIF:</strong> {nif}</p>
        <p><strong>Email:</strong> {email}</p>
        <p><strong>Telefone:</strong> {phoneNumber}</p>
        <p><strong>Morada:</strong> {city}</p>
        <p><strong>Zona:</strong> {region}</p>
        <p><strong>Latitude:</strong> {lat}</p>
        <p><strong>Longitude:</strong> {long}</p>
        <p><strong>Data de criação:</strong> {new Date(createdAt).toLocaleDateString()}</p>
         {/* Verificando se 'species' é uma string e convertendo em array */}
         <p><strong>Espécies:</strong></p>
        {produtorData.species && Array.isArray(produtorData.species) ? (
          produtorData.species.map((specie, index) => (
            <p key={index}>- {formatAcornType(specie)}</p>
          ))
        ) : (
          produtorData.species?.split(',').map((specie, index) => (
            <p key={index}>- {specie.trim()}</p>
          ))
        )}
     
        </div>
        <div className="col-md-1">    <AiFillEdit size={30}   style={{ color: isHovered ? 'var(--focus-color)' : 'var(--primary-color)', cursor: 'pointer' }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)} onClick={() => navigateToEdit(`EditUser?id=${localStorage.userId}` )} /></div>
    </div>
      </div>
    </div>
  );
};

const WarehouseStock = ({
  stockBolota,
  showModal,
  setShowModal,
  setIsEditing,
  setEditedProduct,
  isEditing,
  editedProduct,
  onModalClose, 
}) => {
  const chartStockData = {
    labels: stockBolota.map((item) => item.acornType),
    datasets: [
      {
        data: stockBolota.map((item) => item.quantity),
        backgroundColor: ["#ffa500", "#5d643f", "#8e9475"],
      },
    ],
  };

  const handleEditClick2 = (product) => {
    setIsEditing(true);
    setEditedProduct(product);
  };


  const StockBolotaList = ({ stockBolota }) => {
    return (
      <div className="stock-bolota-container">
        <div className="stock-bolota-header">
          <span className="stock-bolota-header-cell">Espécie</span>
          <span className="stock-bolota-header-cell">Quantidade</span>
          <span className="stock-bolota-header-cell">Preço</span>
          <span className="stock-bolota-header-cell"></span>
        </div>
        {stockBolota.map((item, index) => (
          <div key={index} className="stock-bolota-item">
            <span className="stock-bolota-cell">{item.acornType}</span>
            <span className="stock-bolota-cell">{item.quantity}kg</span>
            <span className="stock-bolota-cell">{item.price}€</span>
            <span className="stock-bolota-cell">
              <AiFillEdit size={15} onClick={() => handleEditClick2(item)} />
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="card warehouse-stock-card">
      <div className="card-body">
        <h5 className="card-title">Stock Armazém</h5>
        <StockBolotaList stockBolota={stockBolota} />
        <Doughnut data={chartStockData} />
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <button
            className="col-12 add-btn"
            style={{ background: "var(--focus-color)", marginTop: "5%" }}
            onClick={() => setShowModal(true)}
          >
            Adicionar
          </button>
        </div>

        <AddBolotaModal isOpen={showModal} onClose={onModalClose} />

        {isEditing && (
          <EditBolotaModal
            isOpen={isEditing}
            onClose={onModalClose}
            isEditMode={true}
            initialProduct={editedProduct}
          />
        )}
      </div>
    </div>
  );
};

const WarehouseHistory = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Sort the history in descending order by date
  const sortedHistory = [...history].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  return (
    <div className="card warehouse-stock-card">
      <div className="card-body">
        <div className="warehouse-history-container">
          <h5 className="card-title">Histórico Armazém</h5>
          <div className="warehouse-history-header">
            <div className="warehouse-history-cell">Date</div>
            <div className="warehouse-history-cell">Espécie</div>
            <div className="warehouse-history-cell">Qt.</div>
            <div className="warehouse-history-cell">Mov.</div>
          </div>
          <div className="warehouse-history-body">
            {sortedHistory.map((item, index) => (
              <div key={index} className="warehouse-history-item">
                <span className="warehouse-history-cell">
                  {new Date(item.timestamp).toLocaleDateString()}
                </span>
                <span className="warehouse-history-cell">{item.acornType}</span>
                <span className="warehouse-history-cell">{item.quantity}</span>
                <span className="warehouse-history-cell">
                  {item.transactionType === "IN" ? (
                    <MdOutlineAddBox color="#43806c" />
                  ) : (
                    <AiOutlineMinusSquare color="#ad1126" />
                  )}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProducerLayout;
