import React from 'react';
import "../Admin/UsersAdmin.css"; 

const DataModal = ({ message, delivery_date, onConfirm, onCancel }) => (
  <div className="modal-overlay">
    <div className="modal-content">
      <button className="close-button" onClick={onCancel}>X</button>
      <p>{message}</p>
      <div className="price-input-container">
        {delivery_date}
      </div>
      <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', marginTop: '10px' }}>
        <button onClick={onConfirm}>Sim</button>
        <button style={{ backgroundColor: "#A9A9A9", color: "white" }} onClick={onCancel}>Cancelar</button>
      </div>
    </div>
  </div>
);

export default DataModal;
