import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { AiOutlineUserAdd } from "react-icons/ai";
import RegisterClient from "./RegisterClient";
import RegisterProducer from "./RegisterProducer";
import RegisterTransformer from "./RegisterTransformer";

import { URL } from "../../config";
import "./Register.css";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(true);
  const history = useHistory();
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  let [producerInfo, setProducerInfo] = useState({
    name: "",
    nif: "",
    street: "",
    city: "",
    
    postalCode: "",
    packaging_conditions:"",
    capacity_deliver:"",
    number_deliver:"",
    conditions:"",
    conditions_location:"",
    distrito: "",
    country: "",
    phoneNumber: "",
    lat: "",
    long: "",
    region:"",
    species: [],
    certifications:[],
  });

  let [clientInfo, setClientInfo] = useState({
    name: "",
    nif: "",
    street: "",
    city: "",
    postalCode: "",
    distrito: "",
    country: "",
    phoneNumber: "",
    lat: "",
    long: "",
    region:"",
    //productQuantity:"",
    category:[],
  });

  let [transformadorInfo, setTransformadorInfo] = useState({
    name: "",
    nif: "",
    street: "",
    city: "",
    postalCode: "",
    distrito: "",
    country: "",
    phoneNumber: "",
    lat: "",
    region:"",
    long: "",
    type_service: "",
    activity_sector: "",
    secagem: false,
    descasque: false,
    taninos: false,
    torra: false,
    moagem: false,
  });


  const handleProducerInfo = (info) => {
    setProducerInfo(info);
    //setClientInfo(info);
    console.log("producerInfo",info);
  };

  const handleClientInfo = (info) => {
    setClientInfo(info);
  };

 const handleTransformadorInfo = (info) => {
  setTransformadorInfo(info);
  console.log("transformadorInfo",info);
  };

  const isValidPasswordTest = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/;
    return regex.test(password);
  };

  useEffect(() => {
    //console.log(producerInfo);
    if (producerInfo)
      if (!isValidPasswordTest(password) && password !== "") {
        // console.log(isDataValid(producerInfo));
        setError(
          "Password deve conter pelo menos 6 caracteres, 1 Maiúscula, 1 Minuscula e 1 especial"
        );
      } else {
        setError("");
      }
  }, [password]);

  const isDataValid = (data) => {
    const requiredFields = [
      "name",
      "nif",
      "street",
      "city",
      "postalCode",
      "district",
      "country",
      "lat",
      "long",
      "phoneNumber",
      "region",
      
    ];

    for (const field of requiredFields) {
      const fieldValue = data[field];

      if (
        !fieldValue ||
        (typeof fieldValue === "string" && fieldValue.trim() === "")
      ) {
        return false;
      }
    }

    // Check if NIF and phone number are valid
    const isValidNif = /^\d{9}$/.test(data["nif"]);
    const isValidPhoneNumber = /^\d{9}$/.test(parseInt(data["phoneNumber"]));

    return isValidNif && isValidPhoneNumber;
  };

  const isFormValid = () => {
    return (
      email !== "" && password !== "" && confirmPassword !== "" && role !== ""
    );
  };

  const checkAvailability = async (value, endpoint) => {
    try {
      const response = await fetch(
        `${URL}/webland-api/user/${endpoint}/${value}`
      );
      const data = await response.json();
      console.log(data);
      return data.isAvailable;
    } catch (error) {
      console.error("Error checking availability:", error);
      return false;
    }
  };

  const handleUsernameChange = async (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername);

    if (!newUsername) {
      setError("Campo obrigatório");
      return;
    }

    try {
      const isAvailable = await checkAvailability(
        newUsername,
        "check-username"
      );
      setIsUsernameAvailable(isAvailable);

      if (isAvailable) {
        setError("");
      } else {
        setError("Username já existente");
      }
    } catch (error) {
      setError("Failed to check username availability");
    }
  };

  const handleEmailChange = async (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!newEmail) {
      setIsEmailAvailable(null);
      setError("");
      return; // Return early to avoid further checks
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(newEmail)) {
      setError("Email inválido");
      setIsEmailAvailable(false);
    } else {
      try {
        const isAvailable = await checkAvailability(newEmail, "check-email");
        setIsEmailAvailable(isAvailable);

        if (isAvailable) {
          setError("");
        } else {
          setError("Email já existente");
        }
      } catch (error) {
        setError("Failed to check email availability");
      }
    }
  };

  const handleEnterPress = async () => {
    const isAvailable = await checkAvailability(username, "check-username");
    if (isAvailable) {
      setUsername(username);
      setIsUsernameAvailable(isAvailable);
    }

    const isEmailAvailable = await checkAvailability(email, "check-email");
    if (isEmailAvailable) {
      setEmail(email);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== password) {
      setError("Passwords não coincidem.");
    } else {
      setError("");
    }
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    console.log("depois",role);
  };

  const handleSubmit = async (e) => {
    console.log("submit", producerInfo);
    e.preventDefault();
    setSubmitButtonClicked(true);
  
    if (password !== confirmPassword) {
      setError("Passwords não coincidem.");
      return;
    }
  
    if (!isFormValid()) {
      setError("Por favor, preencha todos os campos obrigatórios.");
      return;
    }
  
    let userInformation;
    if (role === "producer") {
      userInformation = producerInfo;
    } else if (role === "client") {
      userInformation = clientInfo;
    }
    else if (role === "transformer") {
      userInformation = transformadorInfo;
    }
  
    try {
      console.log("o que foi enviado",  username,email,password,role, userInformation)
      const response = await fetch(`${URL}/webland-api/user/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          email,
          password,
          role,
          
          userInformation:userInformation,
          
        }),
       
      });
      console.log("depois",role);
      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message);
        return;
      }
  
      // Registro bem-sucedido, redirecione conforme necessário
      history.push("/registration-success");
    } catch (error) {
      console.error("Error during registration:", error);
      setError("O registro falhou. Por favor, tente novamente mais tarde.");
    }
  };

  return (
    <>
      <div className={`error-message-slider ${error ? "active-error" : ""}`}>
        {error}
      </div>
      <div className="col-5 go-back-btn" style={{cursor:"pointer"}}>
        <p onClick={() => history.push("/account")}>
          <MdArrowBackIosNew
            style={{
              position: "relative",
              top: "0.15rem",
              marginRight: "0.5rem",
            }}
          />
          Retroceder
        </p>
      </div>
      <div style={{padding:"1rem 2rem", overflow:"unset"}}>
        <div className="title-register-wrapper mb-3">
          <AiOutlineUserAdd
            size={35}
            style={{ marginRight: "1rem", position: "relative", top: "0.7rem" }}
          />
          Registo de utilizador
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="col-xl-3 col-md-6 col-sm-12 form-group">
            <label htmlFor="username">
              Username:{" "}
              {isUsernameAvailable && username !== "" ? (
                <span className="availability-icon" style={{ color: "green" }}>
                  &#x2713;
                </span>
              ) : (
                username !== "" && (
                  <span className="availability-icon" style={{ color: "red" }}>
                    &#x2717;
                  </span>
                )
              )}
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={handleUsernameChange}
              required
              className="form-control"
            />
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12 form-group">
            <label htmlFor="email">
              Email:{" "}
              {isEmailAvailable && email !== "" ? (
                <span className="availability-icon" style={{ color: "green" }}>
                  &#x2713;
                </span>
              ) : (
                email !== "" && (
                  <span className="availability-icon" style={{ color: "red" }}>
                    &#x2717;
                  </span>
                )
              )}
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
              className="form-control"
            />
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12 form-group">
            <label htmlFor="password">
              Password:
              {isValidPasswordTest(password) ? (
                password!=="" && <span className="availability-icon" style={{ color: "green" }}>
                  &#x2713;
                </span>
              ) : (
                password!=="" && <span className="availability-icon" style={{ color: "red" }}>
                  &#x2717;
                </span>
              )}
            </label>

            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              name="password"
              required
              className="form-control"
            />
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12 form-group">
            <label htmlFor="confirmPassword">
              Confirmar Password:
              {password === confirmPassword &&
              isValidPasswordTest(confirmPassword) ? (
                confirmPassword!=="" && <span className="availability-icon" style={{ color: "green" }}>
                  &#x2713;
                </span>
              ) : (
                confirmPassword!=="" &&<span className="availability-icon" style={{ color: "red" }}>
                  &#x2717;
                </span>
              )}
            </label>

            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </div>

          <div className="col-xl-1 col-md-4 col-sm-12 form-group">
            <label htmlFor="role">Tipo de utilizador:</label>
            <select
              id="role"
              name="role"
              required
              className="form-control"
              onChange={handleRoleChange}
              defaultValue="" // Set default value to empty
            >
              <option value="" disabled hidden>
                Selecione
              </option>
              <option value="producer">Produtor</option>
              <option
                value="transformer"
                //disabled={true}
                //style={{ color: "lightgray" }}
              >
                Transformador
              </option>
              <option
                value="client"
                //disabled={true}
               // style={{ color: "lightgray" }}
              >
                Cliente
              </option>
            </select>
          </div>

          {role === "client" && (
            <div className="col-xl-6 col-md-12 col-sm-12">
            <RegisterClient onClientInfoChange={handleClientInfo}/></div>
          )}
          {role === "producer" && (
            <div className="col-xl-6 col-md-12 col-sm-12">

            <RegisterProducer onProducerInfoChange={handleProducerInfo} />
            </div>
          )}
          {role === "transformer" && (
            <div className="col-xl-6 col-md-12 col-sm-12">

            <RegisterTransformer onTranformerInfoChange={handleTransformadorInfo} />
            </div>
          )}


          <button
            type="button" // Change the type to "button"
            className="col-xl-6 col-md-6 col-sm-12 default-button"
            onClick={(e) => {
              // setSubmitButtonClicked(true);
              handleSubmit(e);
            }}
            onKeyDown={(e) => e.key === "Enter" /*&& handleEnterPress()*/}
            disabled={
              !isFormValid() ||
              password !== confirmPassword 
              || !isDataValid(producerInfo)
            }
          >
            Registar
          </button>
        </form>
      </div>
    </>
  );
};

export default Register;
