import React, { useState, useEffect, useRef, useContext } from "react";
import { RiUserLine } from "react-icons/ri";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineDown, AiOutlineUp, AiOutlineBell  } from "react-icons/ai";
import WeblandHorizontalWhite from "../../assets/svg/LogoWeblandHorizontalWhite";
import ModalExit from "./ModalExit";
import { MdNotifications } from "react-icons/md";
import { useSelector, useDispatch } from 'react-redux';
import { setMessages, selectUnreadMessageCount, fetchMessagesById } from '../../redux/messageSlice';
import "./Navbar.css";
import { URL } from "../../config";
import { AuthContext } from "../AuthContext";
 
  const Navbar = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const dropdownRef = useRef(null);
    const {token} = useContext(AuthContext)
    const dispatch = useDispatch();
    const unreadMessageCount = useSelector(selectUnreadMessageCount);
    const {logoutUser} = useContext(AuthContext);
   

  const history = useHistory();

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };
  

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };  

  const handleExitClick = () => {
    setShowModal(true);
  };

  const handleConfirmExit = () => {
    // localStorage.clear();
    // window.location.pathname = "/";
    logoutUser();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    fetchMessagesById();
  }, [])

  // useEffect(()=>{
  //   setInterval(() => {
  //     dispatch(fetchMessagesById(localStorage.id))     
  //   }, 60000);
  // })


  const fetchMessagesById = async () =>{
    const messages = await fetch((`${URL}/webland-api/user/messages/${localStorage.getItem("userId")}`,  {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      }}));
    const data = await messages.json();
    //console.log(data)
    if (!data){
      const unreadMessagesCount = data?.filter(message => !message.read).length;
    }
    else return
    //setNewMessages(unreadMessagesCount);
  }

  return (
    <nav className="navbar">
      <div className="navbar-logo" style={{cursor: localStorage?.getItem('user').includes("admin") ? "pointer" : "default"}}>
      {localStorage.getItem('user').includes("admin") ? <WeblandHorizontalWhite onClick={() => history.push("/dashboard")} /> : <WeblandHorizontalWhite /> }
      </div>
     
      <div className="navbar-user">
        <div className="navbar-bell" onClick={() => history.push("/notifications")}>
      {unreadMessageCount !==0 && <span className="navbar-bell-messages-indicator">{unreadMessageCount}</span>}
        <AiOutlineBell  size={24}  />

        </div>
        <RiUserLine size={24} className="user-icon" onClick={toggleDropdown} />
        {!isDropdownOpen ? (
          <AiOutlineDown size={14} className="user-icon-toggle" onClick={toggleDropdown} />
        ) : (
          <AiOutlineUp size={14} className="user-icon-toggle" onClick={toggleDropdown} />
        )}
        {isDropdownOpen && (
          <div className="user-dropdown active" ref={dropdownRef}>
            <ul>
            { localStorage.getItem('user').includes("admin") && <div>

              <li onClick={() => {history.push("/dashboard"); toggleDropdown()}}>Dashboard</li>
              <li onClick={() => {history.push("/users"); toggleDropdown()}}>Utilizadores</li>
              <li onClick={() => {history.push("/orders"); toggleDropdown()}}>Encomendas</li>
              <li onClick={() => {history.push("/market"); toggleDropdown()}}>Market</li>
              <hr className="user-dropdown-divider" />              
              </div>}
              {localStorage.getItem("user") === "admin" && (
              <li onClick={() => {history.push("/users/profile"); toggleDropdown()}}>Perfil</li>  )}
                 {localStorage.getItem("user") === "producer" && (
              <li onClick={() => {history.push("/ProfileProducer"); toggleDropdown()}}>Perfil</li>  )}
               {localStorage.getItem("user") === "admin" && (
              <li onClick={() => {history.push("/settings"); toggleDropdown()}}>Definições</li> )}
              <hr className="user-dropdown-divider" />
              <li onClick={() => handleExitClick()}>Logout</li>
            </ul>
          </div>
        )}
      </div> 
      {showModal && (
        <ModalExit onClose={handleCloseModal} onConfirm={handleConfirmExit} />
      )}
    </nav>
  );
};

export default Navbar;
